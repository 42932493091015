import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import { formatName } from "shared/utils/common";
import {
  integrationPropType,
} from "shared/prop-types";
import {
  activeAdvisorsSelector,
  settingsDispatchCodesSelector,
} from "store/selectors/settings-selectors";
import { updateLoanerIntegrationOptions } from "store/actions/settings-actions";
import HeaderEdit from "components/common/HeaderEdit";
import Input from "components/common/Input";
import Accordion from "components/common/Accordion";
import SwitchWithLabel from "components/common/SwitchWithLabel";

import styles from "../styles.module.scss";

const LoanerIntegration = ({
  integration: {
    active,
    settings,
    id,
    kind,
  },
  updateIntegrationHandler,
}) => {
  const [loanerSettings, setLoanerSettings] = useState({});
  const [isActive, setIsActive] = useState(active);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLoanerSettings(settings);
  }, [settings]);

  const save = () => {
    const params = {
      settings: loanerSettings,
      active: isActive,
    };

    updateIntegrationHandler(id, params);
    setIsEditing(false);
  };

  const cancelChanges = () => {
    setLoanerSettings({ ...settings });
    setIsActive(active);
    setIsEditing(false);
  };

  return (
    <Accordion
      title={formatName(kind)}
      customClass={styles.accordion}
    >
      <section className={styles.container}>
        <SwitchWithLabel
          id="active-switch"
          label="Active"
          disabled={!isEditing}
          checked={isActive}
          onChange={() => setIsActive((prev) => !prev)}
        />
        <section className={styles.inputsContainer}>
          {Object.keys(settings).map((keyName) => (
            keyName !== "both_conditions_required"
          ) && (
            <Input
              label={
                keyName === "min_appointment_duration"
                  ? "Minimum appointment duration (minutes)"
                  : formatName(keyName)
              }
              value={loanerSettings[keyName]}
              onChange={(value) => setLoanerSettings((prev) => {
                return {
                  ...prev,
                  [keyName]: value,
                };
              })}
              key={keyName}
              type="number"
              min={0}
              disabled={!isEditing}
            />
          ))}
          <SwitchWithLabel
            id="both-switch"
            label="Both conditions required"
            disabled={!isEditing}
            checked={loanerSettings.remote_access}
            onChange={() => setLoanerSettings((prev) => {
              return {
                ...prev,
                both_conditions_required: !prev.both_conditions_required,
              };
            })}
            containerClass={styles.switch}
          />
        </section>
      </section>
      <HeaderEdit
        isEditing={isEditing}
        handleCancel={cancelChanges}
        handleSave={save}
        handleEdit={() => setIsEditing(true)}
      />
    </Accordion>
  );
};

LoanerIntegration.propTypes = {
  integration: integrationPropType.isRequired,
  updateIntegrationHandler: func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    serviceAdvisors: activeAdvisorsSelector(state),
    dispatchCodes: settingsDispatchCodesSelector(state),
  };
};

const actions = {
  updateIntegrationHandler: updateLoanerIntegrationOptions,
};

export default connect(mapStateToProps, actions)(LoanerIntegration);
