import * as moment from "moment-timezone";
import dateFns from "date-fns";
import { formatTimeInfoUS } from "shared/utils/datetime";
import {
  cancelBooking,
  cancelDriverJob,
  cancelMobileTechnicianJob,
  checksJobDistance,
  createNewCustomer,
  createNewDriverJob,
  createNewMobileTechnicianJob,
  createQuestionnairesAnswers,
  fetchAppointmentSlots,
  fetchAppointmentVideos,
  fetchAvailableLoaners,
  fetchAvailableTransport,
  fetchBooking,
  fetchDealershipDrivers,
  fetchDealershipMenuItems,
  fetchDealershipMobileTechnicians,
  fetchJobSlots,
  fetchListOfAvailableQuestionnaires,
  fetchTecnhiciansJobSlots,
  fetchVehicleRO,
  fetchVehicleRecalls,
  generateRepairOrder,
  sendCheckinSms,
  updateAppointmentToArrived,
  updateBooking,
  updateCustomer,
  updateDriverJob,
  updateLocalAppointment,
  updateMobileTechnicianJob,
  updateVehicleSet,
  validateWaiterPossibility,
} from "shared/api";
import {
  fetchAppointmentByGuid,
} from "shared/apiCheckin";
import { fetchVideos } from "shared/apiArriveGetCarmen";
import {
  authChannelKeySelector,
  authTokenSelector,
} from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { settingsDataSelector, settingsResponsibleTeamTagSelector, settingsTimezoneSelector } from "store/selectors/settings-selectors";
import {
  bookingActiveDropOffJobSelector,
  bookingActiveMobileTechnicianJobSelector,
  bookingActivePickupJobSelector,
  bookingDetailsChosenTimeSlotSelector,
  bookingDetailsCustomerSelector,
  bookingDetailsDataSelector,
  bookingDetailsGuidSelector,
  bookingDetailsIsDropOffSelector,
  bookingDetailsIsNotCheckedInSelector,
  bookingDetailsIsPickUpSelector,
  bookingDetailsQuestionnairesIdSelector,
  bookingDetailsRemoteJobDataSelector,
  bookingDetailsSelectedServicesSelector,
  bookingDetailsSelector,
  bookingDetailsSendSmsSelector,
  bookingDetailsVehicleSelector,
  bookingDetailsisMobileTechnicianSelector,
  bookingSelectedRecallsSelector,
  bookingServiceLocationSelector,
  bookingVehicleData,
} from "store/selectors/booking-details-selectors";
import {
  CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS,
  setLoadingState as setCheckinChatLoadingState,
} from "store/actions/checkin-chat-actions";

import { formatInput, totalCost, totalServiceTime } from "shared/utils/common";
import { SCHEDULER_SOURCE } from "shared/constants";
import { SOURCE_CONCIERGE, SOURCE_USER } from "shared/constants/text-messages";
import { appCheckinIdSelector } from "store/selectors/checkin-app-selectors";
import {
  resetSchedulingProcessState,
  setCurrentCustomer,
  setCurrentVehicle,
} from "./scheduling-process-actions";
import { fetchTeamAdvisorsByServices } from "./settings-actions";

export const BOOKING_DETAILS_SET_LOADING_STATE = "BOOKING_DETAILS_SET_LOADING_STATE";
export const BOOKING_DETAILS_CLEAN_DATA = "BOOKING_DETAILS_CLEAN_DATA";
export const BOOKING_DETAILS_RESET_STATE = "BOOKING_DETAILS_RESET_STATE";

export const BOOKING_DETAILS_FETCH_SUCCESS = "BOOKING_DETAILS_FETCH_SUCCESS";
export const BOOKING_DETAILS_FETCH_FAILURE = "BOOKING_DETAILS_FETCH_FAILURE";

export const BOOKING_DETAILS_FETCH_RECALLS = "BOOKING_DETAILS_FETCH_RECALLS";
export const BOOKING_DETAILS_FETCH_RECALLS_SUCCESS = "BOOKING_DETAILS_FETCH_RECALLS_SUCCESS";
export const BOOKING_DETAILS_FETCH_RECALLS_ERROR = "BOOKING_DETAILS_FETCH_RECALLS_ERROR";

export const BOOKING_DETAILS_DELETE_BOOKING = "BOOKING_DETAILS_DELETE_BOOKING";
export const BOOKING_DETAILS_DELETE_BOOKING_SUCCESS = "BOOKING_DETAILS_DELETE_BOOKING_SUCCESS";
export const BOOKING_DETAILS_DELETE_BOOKING_FAILURE = "BOOKING_DETAILS_DELETE_BOOKING_FAILURE";

export const BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS = "BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS";
export const BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS = "BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS";
export const BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_FAILURE = "BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_FAILURE";

export const BOOKING_DETAILS_GENERATE_REPAIR_ORDER_SUCCESS = "BOOKING_DETAILS_GENERATE_REPAIR_ORDER_SUCCESS";
export const BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE = "BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE";

export const BOOKING_DETAILS_SET_CURRENT_STEP = "BOOKING_DETAILS_SET_CURRENT_STEP";
export const BOOKING_DETAILS_SET_SERVICE_SELECTION = "BOOKING_DETAILS_SET_SERVICE_SELECTION";
export const BOOKING_DETAILS_SET_SERVICE_ADVISOR = "BOOKING_DETAILS_SET_SERVICE_ADVISOR";
export const BOOKING_DETAILS_SET_CHOSEN_TIMESLOT = "BOOKING_DETAILS_SET_CHOSEN_TIMESLOT";
export const BOOKING_DETAILS_SET_CHOSEN_DROP_OFF_TIMESLOT = "BOOKING_DETAILS_SET_CHOSEN_DROP_OFF_TIMESLOT";
export const BOOKING_DETAILS_SET_CLIENT_WAITING = "BOOKING_DETAILS_SET_CLIENT_WAITING";
export const BOOKING_DETAILS_SET_CLIENT_TRANSPORT = "BOOKING_DETAILS_SET_CLIENT_TRANSPORT";
export const BOOKING_DETAILS_SET_REMOTE_JOB_COLLECTION_TIME = "BOOKING_DETAILS_SET_REMOTE_JOB_COLLECTION_TIME";
export const BOOKING_DETAILS_SET_CHECKED_IN_STATUS = "BOOKING_DETAILS_SET_CHECKED_IN_STATUS";
export const BOOKING_DETAILS_SET_SEND_SMS = "BOOKING_DETAILS_SET_SEND_SMS";

export const BOOKING_DETAILS_FETCH_DAYS = "BOOKING_DETAILS_FETCH_DAYS";
export const BOOKING_DETAILS_FETCH_DAYS_SUCCESS = "BOOKING_DETAILS_FETCH_DAYS_SUCCESS";
export const BOOKING_DETAILS_FETCH_DAYS_FAILURE = "BOOKING_DETAILS_FETCH_DAYS_FAILURE";
export const BOOKING_DETAILS_FETCH_JOB_SLOTS_SUCCESS = "BOOKING_DETAILS_FETCH_JOB_SLOTS_SUCCESS";
export const BOOKING_DETAILS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS = "BOOKING_DETAILS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS";

export const BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT = "BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT";
export const BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_SUCCESS = "BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_SUCCESS";
export const BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_FAILURE = "BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_FAILURE";

export const BOOKING_DETAILS_TRIGGER_CHECKIN_SMS = "BOOKING_DETAILS_TRIGGER_CHECKIN_SMS";
export const BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_SUCCESS = "BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_SUCCESS";
export const BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE = "BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE";

export const BOOKING_DETAILS_UPDATE_APPOINTMENT = "BOOKING_DETAILS_UPDATE_APPOINTMENT";
export const BOOKING_DETAILS_UPDATE_APPOINTMENT_SUCCESS = "BOOKING_DETAILS_UPDATE_APPOINTMENT_SUCCESS";
export const BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE = "BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE";

export const BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS = "BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS";
export const BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_FAILURE = "BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_FAILURE";

export const BOOKING_DETAILS_SET_NEW_DRIVER_JOB = "BOOKING_DETAILS_SET_NEW_DRIVER_JOB";
export const BOOKING_DETAILS_SET_NEW_DRIVER_JOB_SUCCESS = "BOOKING_DETAILS_SET_NEW_DRIVER_JOB_SUCCESS";
export const BOOKING_DETAILS_SET_NEW_DRIVER_JOB_FAILURE = "BOOKING_DETAILS_SET_NEW_DRIVER_JOB_FAILURE";

export const BOOKING_DETAILS_SET_NEW_MOBILE_TECHNICIAN_JOB = "BOOKING_DETAILS_SET_NEW_MOBILE_TECHNICIAN_JOB";
export const BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB = "BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB";
export const BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_SUCCESS = "BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_SUCCESS";
export const BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_FAILURE = "BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_FAILURE";

export const BOOKING_DETAILS_SET_RECALLS_SELECTION = "BOOKING_DETAILS_SET_RECALLS_SELECTION";

export const BOOKING_DETAILS_FETCH_VIDEOS = "BOOKING_DETAILS_FETCH_VIDEOS";
export const BOOKING_DETAILS_FETCH_VIDEOS_SUCCESS = "BOOKING_DETAILS_FETCH_VIDEOS_SUCCESS";
export const BOOKING_DETAILS_FETCH_VIDEOS_FAILURE = "BOOKING_DETAILS_FETCH_VIDEOS_FAILURE";

export const BOOKING_DETAILS_UPDATE_DRIVER_JOB = "BOOKING_DETAILS_UPDATE_DRIVER_JOB";
export const BOOKING_DETAILS_UPDATE_DRIVER_JOB_SUCCESS = "BOOKING_DETAILS_UPDATE_DRIVER_JOB_SUCCESS";
export const BOOKING_DETAILS_UPDATE_DRIVER_JOB_FAILURE = "BOOKING_DETAILS_UPDATE_DRIVER_JOB_FAILURE";

export const BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA = "BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA";
export const BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS = "BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS";
export const BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS_WITHOUT_DMS = "BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS_WITHOUT_DMS";
export const BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE = "BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE";

export const BOOKING_DETAILS_UPDATE_CUSTOMER_DATA = "BOOKING_DETAILS_UPDATE_CUSTOMER_DATA";
export const BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_SUCCESS = "BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_SUCCESS";
export const BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE = "BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE";

export const BOOKING_DETAILS_CREATE_CUSTOMER = "BOOKING_DETAILS_CREATE_CUSTOMER";
export const BOOKING_DETAILS_CREATE_CUSTOMER_SUCCESS = "BOOKING_DETAILS_CREATE_CUSTOMER_SUCCESS";
export const BOOKING_DETAILS_CREATE_CUSTOMER_FAILURE = "BOOKING_DETAILS_CREATE_CUSTOMER_FAILURE";

export const BOOKING_DETAILS_UPDATE_WAITER_ALLOWED = "BOOKING_DETAILS_UPDATE_WAITER_ALLOWED";
export const BOOKING_DETAILS_UPDATE_WAITER_ALLOWED_FAILURE = "ERROR_WAITER_ALLOWED_FAILURE";
export const BOOKING_DETAILS_FETCH_VEHICLE_DATA = "BOOKING_DETAILS_FETCH_VEHICLE_DATA";
export const BOOKING_DETAILS_FETCH_VEHICLE_RO = "BOOKING_DETAILS_FETCH_VEHICLE_RO";
export const BOOKING_DETAILS_FETCH_VEHICLE_RO_SUCCESS = "BOOKING_DETAILS_FETCH_VEHICLE_RO_SUCCESS";
export const BOOKING_DETAILS_FETCH_VEHICLE_RO_FAILURE = "BOOKING_DETAILS_FETCH_VEHICLE_RO_FAILURE";

export const BOOKING_DETAILS_UPDATE_AVAILABLE_LOANERS = "BOOKING_DETAILS_UPDATE_AVAILABLE_LOANERS";

export const BOOKING_DETAILS_SET_IS_PICK_UP = "BOOKING_DETAILS_SET_IS_PICK_UP";
export const BOOKING_DETAILS_SET_IS_DROP_OFF = "BOOKING_DETAILS_SET_IS_DROP_OFF";
export const BOOKING_DETAILS_SET_BOOKING_REMOTE_JOB_DATA = "BOOKING_DETAILS_SET_BOOKING_REMOTE_JOB_DATA";

export const BOOKING_DETAILS_SET_IS_MOBILE_TECHNICIAN = "BOOKING_DETAILS_SET_IS_MOBILE_TECHNICIAN";

export const BOOKING_DETAILS_SET_IS_DISTANCE_LIMIT_IGNORED = "BOOKING_DETAILS_SET_IS_DISTANCE_LIMIT_IGNORED";

export const BOOKING_DETAILS_JOB_REACHABLE_LOADING = "BOOKING_DETAILS_JOB_REACHABLE_LOADING";
export const BOOKING_DETAILS_JOB_REACHABLE = "BOOKING_DETAILS_JOB_REACHABLE";

export const BOOKING_DETAILS_FETCH_DRIVERS_INFO_SUCCESS = "BOOKING_DETAILS_FETCH_DRIVERS_INFO_SUCCESS";
export const BOOKING_DETAILS_FETCH_DRIVERS_INFO_FAILURE = "BOOKING_DETAILS_FETCH_DRIVERS_INFO_FAILURE";

export const BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS = "BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS";
export const BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE = "BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE";

export const BOOKING_DETAILS_SET_REMOTE_SERVICE_LOCATION = "BOOKING_DETAILS_SET_REMOTE_SERVICE_LOCATION";

export const BOOKING_DETAILS_SET_ARRIVED = "BOOKING_DETAILS_SET_ARRIVED";

export const BOOKING_DETAILS_FETCH_QUESTIONNAIRES = "BOOKING_DETAILS_FETCH_QUESTIONNAIRES";
export const BOOKING_DETAILS_FETCH_QUESTIONNAIRES_SUCCSESS = "BOOKING_DETAILS_FETCH_QUESTIONNAIRES_SUCCSESS";
export const BOOKING_DETAILS_FETCH_QUESTIONNAIRES_ERROR = "BOOKING_DETAILS_FETCH_QUESTIONNAIRES_ERROR";
export const BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS = "BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS";
export const BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_ERROR = "BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_ERROR";

const setLoadingState = () => {
  return {
    type: BOOKING_DETAILS_SET_LOADING_STATE,
    payload: {},
  };
};

const cleanBookingDetailsData = () => {
  return {
    type: BOOKING_DETAILS_CLEAN_DATA,
    payload: {},
  };
};

const setNewDriverJobState = () => {
  return {
    type: BOOKING_DETAILS_SET_NEW_DRIVER_JOB,
    payload: {
      isLoading: true,
    },
  };
};

const updateDriverJobState = () => {
  return {
    type: BOOKING_DETAILS_UPDATE_DRIVER_JOB,
    payload: {
      isLoading: true,
    },
  };
};

const setNewMobileTechnicianJobState = () => {
  return {
    type: BOOKING_DETAILS_SET_NEW_MOBILE_TECHNICIAN_JOB,
    payload: {
      isLoading: true,
    },
  };
};

const updateMobileTechnicianJobState = () => {
  return {
    type: BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB,
    payload: {
      isLoading: true,
    },
  };
};

export const resetBookingDetails = () => (dispatch) => dispatch({
  type: BOOKING_DETAILS_RESET_STATE,
});

export const retrieveRepairOrders = (id) => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  dispatch({ type: BOOKING_DETAILS_FETCH_VEHICLE_RO });

  return fetchVehicleRO(
    dealershipId,
    token,
    id,
  ).then((result) => {
    dispatch({
      type: BOOKING_DETAILS_FETCH_VEHICLE_RO_SUCCESS,
      payload: result,
    });
  })
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_FETCH_VEHICLE_RO_FAILURE,
        payload: error,
      });
    });
};

const getQuestionnairesLoadingState = () => {
  return {
    type: BOOKING_DETAILS_FETCH_QUESTIONNAIRES,
  };
};

export const getListOfAvailableQuestionnaires = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const isMobileTechnician = bookingDetailsisMobileTechnicianSelector(getState());
  const kind = isMobileTechnician ? "mobile_technician" : "";

  fetchListOfAvailableQuestionnaires(dealershipId, kind, token)
    .then((result) => {
      dispatch(getQuestionnairesLoadingState());
      dispatch({
        type: BOOKING_DETAILS_FETCH_QUESTIONNAIRES_SUCCSESS,
        payload: result.find((item) => item.kind === "mobile_technician"),
      });
    })
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_FETCH_QUESTIONNAIRES_ERROR,
        payload: error,
      });
    });
};

export const addQuestionnairesAnswers = (answers) => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const {
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
    customer,
    id,
    vehicle,
  } = bookingDetailsDataSelector(getState());

  createQuestionnairesAnswers(
    dealershipId,
    id,
    {
      questionnaire_id: bookingDetailsQuestionnairesIdSelector(getState()),
      customer_id: customer.id,
      vehicle_id: vehicle.id,
      response: answers,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_zipcode,
    },
    token,
  )
    .then((result) => {
      dispatch({
        type: BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS,
        payload: result,
      });
    })
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_ERROR,
        payload: error,
      });
    });
};

export const retrieveBookingDetails = (bookingId, kind) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  dispatch(setLoadingState());
  dispatch(cleanBookingDetailsData());

  try {
    const response = await fetchBooking(dealershipId, bookingId, kind, authToken);
    dispatch({
      type: BOOKING_DETAILS_FETCH_SUCCESS,
      payload: response,
    });

    dispatch(setCheckinChatLoadingState(true));
    const checkinId = appCheckinIdSelector(getState());
    const guid = bookingDetailsGuidSelector(getState());
    const isNotCheckedIn = bookingDetailsIsNotCheckedInSelector(getState());
    const isMobileTechnician = bookingDetailsisMobileTechnicianSelector(getState());
    const booking = bookingDetailsDataSelector(getState());
    const isNotClosed = booking.aasm_state === ("open" || "arrived");

    if (checkinId && guid && isNotCheckedIn && isNotClosed) {
      dispatch({ type: CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS });
      await fetchAppointmentByGuid(checkinId, guid);
      if (isMobileTechnician
        && booking.questionnaire_answers.length === 0) {
        dispatch(getListOfAvailableQuestionnaires());
      }
      dispatch(setCheckinChatLoadingState(false));
    }
  } catch (error) {
    dispatch({
      type: BOOKING_DETAILS_FETCH_FAILURE,
      payload: { error },
    });
  }
};

export const deleteBooking = (bookingId) => (
  dispatch,
  getState,
) => {
  dispatch({ type: BOOKING_DETAILS_DELETE_BOOKING });
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  const channelKey = authChannelKeySelector(getState());
  const sendSms = bookingDetailsSendSmsSelector(getState());

  return cancelBooking(bookingId, channelKey, dealershipId, sendSms, authToken)
    .then((response) => response)
    .catch((error) => dispatch({
      type: BOOKING_DETAILS_DELETE_BOOKING_FAILURE,
      payload: { error },
    }));
};

export const generateRO = (bookingId) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const channel_key = authChannelKeySelector(getState());

  return generateRepairOrder(dealershipId, bookingId, channel_key, token)
    .catch((error) => dispatch({
      type: BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE,
      payload: error,
    }));
};

export const scheduleNewBooking = () => (dispatch, getState) => {
  dispatch(resetSchedulingProcessState());
  dispatch(setCurrentCustomer(bookingDetailsCustomerSelector(getState())));
  dispatch(setCurrentVehicle(bookingDetailsVehicleSelector(getState())));
};

export const setCurrentStep = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_CURRENT_STEP,
    payload,
  };
};

export const setRecallsSelection = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_RECALLS_SELECTION,
    payload,
  };
};

export const fetchRecalls = () => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const booking = bookingDetailsDataSelector(getState());

  const params = {
    vin: booking.vehicle.vin,
    channel_key: authChannelKeySelector(getState()),
  };

  dispatch({ type: BOOKING_DETAILS_FETCH_RECALLS });

  fetchVehicleRecalls(dealershipId, params, token);
};

const setMenuItemsLoadingState = () => {
  return {
    type: BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS,
  };
};

export const fetchMenuItems = () => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const booking = bookingDetailsDataSelector(getState());
  const {
    vehicle_set,
    vehicle,
  } = booking;
  const params = {
    "vehicle_set[make]": vehicle_set.make,
    "vehicle_set[model]": vehicle_set.model,
    "vehicle_set[model_year]": vehicle_set.model_year,
    mileage: vehicle?.mileage || 0,
  };

  dispatch(setMenuItemsLoadingState());
  return fetchDealershipMenuItems(dealershipId, params, token)
    .then((response) => {
      dispatch({
        type: BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_FAILURE,
        payload: error,
      });
    });
};

export const fetchAdvisors = () => (dispatch, getState) => {
  const services = bookingDetailsSelectedServicesSelector(getState());
  const recalls = bookingSelectedRecallsSelector(getState());
  const vehicle = bookingDetailsVehicleSelector(getState());
  const isMobileTechnician = bookingDetailsisMobileTechnicianSelector(getState());

  dispatch(fetchTeamAdvisorsByServices(services, recalls, vehicle, isMobileTechnician));
};

export const setServiceSelection = (payload) => {
  const decisionTreeResults = payload
    .filter((service) => service.decisionTreeResult)
    .map(({ id, decisionTreeResult, decision_tree }) => {
      const details = [{
        source: SOURCE_CONCIERGE,
        text: decision_tree.intro.text,
      }];

      Object.entries(decisionTreeResult).forEach(([key, value]) => {
        details.push({
          source: SOURCE_CONCIERGE,
          text: key,
        });
        details.push({
          source: SOURCE_USER,
          text: value,
        });
      });

      return {
        menu_item_id: id,
        details,
      };
    });

  return {
    type: BOOKING_DETAILS_SET_SERVICE_SELECTION,
    payload: {
      data: payload,
      decisionTreeResults,
    },
  };
};

export const setServiceAdvisor = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_SERVICE_ADVISOR,
    payload,
  };
};

export const setSendSms = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_SEND_SMS,
    payload,
  };
};

export const setChosenTimeSlot = (payload) => (dispatch) => {
  dispatch({
    type: BOOKING_DETAILS_SET_CHOSEN_TIMESLOT,
    payload,
  });
  dispatch({
    type: BOOKING_DETAILS_SET_REMOTE_JOB_COLLECTION_TIME,
    payload: formatTimeInfoUS(payload),
  });
};

export const setChosenDropOffTimeSlot = (payload) => (dispatch) => {
  dispatch({
    type: BOOKING_DETAILS_SET_CHOSEN_DROP_OFF_TIMESLOT,
    payload,
  });
};

const setDaysLoadingState = () => {
  return {
    type: BOOKING_DETAILS_FETCH_DAYS,
  };
};

export const waiterPossibility = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const timeSlot = bookingDetailsChosenTimeSlotSelector(getState());
  const booking = bookingDetailsDataSelector(getState());
  const teamTag = settingsResponsibleTeamTagSelector(getState());

  if (timeSlot.day) {
    const {
      quarterSlot,
      day: {
        full_date,
      },
    } = timeSlot;
    const dealershipTimezone = settingsTimezoneSelector(getState());
    const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";
    const appointmentDatetime = moment.tz(
      `${full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();

    validateWaiterPossibility(
      dealershipId,
      {
        appointment_id: booking.id,
        date_time: appointmentDatetime,
        team_tag_id: teamTag?.id,
      },
      token,
    ).then((response) => {
      dispatch({
        type: BOOKING_DETAILS_UPDATE_WAITER_ALLOWED,
        payload: response.waiter_allowed,
      });
    }).catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_UPDATE_WAITER_ALLOWED_FAILURE,
        payload: error.message,
      });
    });
  }
};

export const getAvailableLoaners = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const timeSlot = bookingDetailsChosenTimeSlotSelector(getState());
  const integrationId = settingsDataSelector(getState()).integrations.find((s) => s.kind === "loaner").id;
  if (timeSlot.day) {
    const {
      quarterSlot,
      day: {
        full_date,
      },
    } = timeSlot;
    const dealershipTimezone = settingsTimezoneSelector(getState());
    const datetimeFormat = "YYYY-MM-DD";
    const appointmentDatetime = moment.tz(
      `${full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();
    fetchAvailableLoaners(
      dealershipId,
      integrationId,
      {
        date: appointmentDatetime,
      },
      token,
    ).then((result) => {
      dispatch({
        type: BOOKING_DETAILS_UPDATE_AVAILABLE_LOANERS,
        payload: result,
      });
    });
  }
};

export const fetchAvailableDays = (
  advisorId,
  month,
  year,
  firstInit = true,
  isDropOff = false,
) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const booking = bookingDetailsDataSelector(getState());
  const vehicle = bookingDetailsVehicleSelector(getState());
  const selectedServices = bookingDetailsSelectedServicesSelector(getState());
  const isLoanerActive = settingsDataSelector(getState()).integrations.find((s) => s.kind === "loaner")?.active;
  const appointmentDatetime = booking.appointment_datetime;
  const appointmentFullDate = dateFns.format(appointmentDatetime, "YYYY-MM-DD");
  const appointmentQuarterSlot = dateFns.format(appointmentDatetime, "HH:mm");
  const menuItemsIds = selectedServices.length
    ? selectedServices.filter(({ kind }) => kind !== "other" && kind !== "recall").map(({ id }) => id)
    : [];
  const recallsIds = selectedServices.length
    ? selectedServices.filter(({ kind }) => kind === "recall").map(({ recall_id }) => recall_id)
    : [];
  const isPickUp = bookingDetailsIsPickUpSelector(getState());
  const isMobileTechnicians = bookingDetailsisMobileTechnicianSelector(getState());
  const serviceLocation = bookingServiceLocationSelector(getState());
  const remoteJobs = bookingDetailsRemoteJobDataSelector(getState());
  const teamTag = settingsResponsibleTeamTagSelector(getState());

  let workingArea;

  if (isPickUp) {
    if (isDropOff) {
      workingArea = serviceLocation.dropOff.zone
      || remoteJobs.dropOff.location_area
      || remoteJobs.pickUp.location_area;
    } else {
      workingArea = serviceLocation.pickUp.zone;
    }
  } else if (isMobileTechnicians) {
    workingArea = serviceLocation.pickUp.zone;
  }

  dispatch(setDaysLoadingState());

  try {
    let response;

    if (isPickUp) {
      response = await fetchJobSlots(dealershipId, {
        menu_items_ids: menuItemsIds,
        recalls_ids: recallsIds,
        vehicle_id: vehicle && vehicle.id,
        month,
        year,
        working_area: workingArea,
        appointment_id: booking.id,
      }, token);

      dispatch({
        type: BOOKING_DETAILS_FETCH_JOB_SLOTS_SUCCESS,
        payload: response,
      });
    }

    if (isMobileTechnicians) {
      response = await fetchTecnhiciansJobSlots(dealershipId, {
        menu_items_ids: menuItemsIds,
        recalls_ids: recallsIds,
        vehicle_id: vehicle && vehicle.id,
        month,
        year,
        working_area: workingArea,
        appointment_id: booking.id,
      }, token);

      dispatch({
        type: BOOKING_DETAILS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS,
        payload: response,
      });
    }

    if (!isPickUp && !isMobileTechnicians && advisorId) {
      response = await fetchAppointmentSlots(
        dealershipId,
        {
          service_advisors_ids: Array.isArray(advisorId) ? advisorId : [advisorId],
          menu_items_ids: menuItemsIds,
          recalls_ids: recallsIds,
          vehicle_id: vehicle && vehicle.id,
          month,
          year,
          appointment_id: booking.id,
        },
        token,
      );

      dispatch({
        type: BOOKING_DETAILS_FETCH_DAYS_SUCCESS,
        payload: response,
      });
    }

    if (firstInit) {
      let matchDay = false;

      if (isDropOff) {
        const dropOffFullDate = dateFns.format(remoteJobs.dropOff.collectionTime, "YYYY-MM-DD");

        matchDay = response.find(
          (day) => day.full_date === dropOffFullDate,
        );
      } else {
        matchDay = response.find(
          (day) => day.full_date === appointmentFullDate,
        );
      }

      if (matchDay) {
        let chosenTimeSlot = {};

        if (isDropOff) {
          const dropOffQuarterSlot = dateFns.format(remoteJobs.dropOff.collectionTime, "HH:mm");

          chosenTimeSlot = {
            day: matchDay,
            quarterSlot: dropOffQuarterSlot,
            overcapacity: false,
            walkin: false,
          };

          dispatch(setChosenDropOffTimeSlot(chosenTimeSlot));
        } else {
          chosenTimeSlot = {
            day: matchDay,
            quarterSlot: appointmentQuarterSlot,
            overcapacity: booking.overcapacity,
            walkin: booking.overcapacity,
          };

          dispatch(setChosenTimeSlot(chosenTimeSlot));
        }
      } else if (advisorId === booking.service_advisor.id) {
        const generatedMatchDay = {
          day_name: dateFns.format(booking.appointment_datetime, "dddd"),
          day_of_month: dateFns.format(booking.appointment_datetime, "MMMM, D"),
          full_date: dateFns.format(booking.appointment_datetime, "YYYY-MM-DD"),
          short_day_name: dateFns.format(booking.appointment_datetime, "dd"),
          slots: [dateFns.format(booking.appointment_datetime, "HH:mm")],
        };

        const chosenTimeSlot = {
          day: generatedMatchDay,
          quarterSlot: generatedMatchDay.slots[0],
          overcapacity: booking.overcapacity,
          walkin: booking.overcapacity,
        };

        dispatch(setChosenTimeSlot(chosenTimeSlot));
      }
    }
    if (!isMobileTechnicians) {
      if (isLoanerActive) {
        dispatch(getAvailableLoaners());
      }

      if (!isPickUp && teamTag) {
        dispatch(waiterPossibility());
      }
    }
  } catch (error) {
    dispatch({
      type: BOOKING_DETAILS_FETCH_DAYS_FAILURE,
      payload: error.message,
    });
  }
};

export const setClientWaiting = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_CLIENT_WAITING,
    payload,
  };
};

export const fetchTransport = () => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const services = bookingDetailsSelectedServicesSelector(getState());
  const { quarterSlot, day } = bookingDetailsChosenTimeSlotSelector(getState());
  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";

  if (day?.full_date) {
    const appointmentDatetime = moment.tz(
      `${day.full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();

    const params = {
      money_spent: totalCost(services),
      appointment_duration: totalServiceTime(services),
      appointment_datetime: appointmentDatetime,
    };
    const token = authTokenSelector(getState());

    dispatch({ type: BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT });
    return fetchAvailableTransport(dealershipId, params, token)
      .then((response) => dispatch({
        type: BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_SUCCESS,
        payload: response,
      }))
      .catch((error) => dispatch({
        type: BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_FAILURE,
        payload: error,
      }));
  }
};

export const setClientTransport = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_CLIENT_TRANSPORT,
    payload,
  };
};

export const setIsDropOff = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_IS_DROP_OFF,
    payload,
  };
};

export const setNewDriverJob = ({
  appointmentId,
  addressData,
  collectionTime,
  jobType,
  mainDriverId,
  coDriverId = "",
  notes = "",
  distance_limit_ignored = false,
  location_area,
  geolocation,
  distance,
  overcapacity = false,
}) => (dispatch, getState) => {
  dispatch(setLoadingState());
  dispatch(setNewDriverJobState());

  const authToken = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));
  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";
  const collectionDatetime = moment.tz(
    `${collectionTime.date} ${collectionTime.time}`,
    datetimeFormat,
    dealershipTimezone,
  ).format();

  const params = {
    appointment_id: appointmentId,
    channel_key,
    collection_time: collectionDatetime,
    job_type: jobType,
    main_driver_id: mainDriverId,
    co_driver_id: coDriverId,
    notes,
    dealership_id,
    distance_limit_ignored,
    ...addressData,
    geolocation,
    location_area,
    distance_in_miles: distance,
    overcapacity,
  };

  return createNewDriverJob(dealership_id, mainDriverId, params, authToken)
    .then(() => dispatch(setIsDropOff(true)));
};

export const setNewMobileTechnicianJob = ({
  appointmentId,
  addressData,
  collectionTime,
  jobType,
  technicianId,
  notes = "",
  location_area,
  distance_limit_ignored = false,
  geolocation,
  distance,
  overcapacity = false,
}) => (dispatch, getState) => {
  dispatch(setNewMobileTechnicianJobState());

  const authToken = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealershipId = Number(dealershipIdSelector(getState()));
  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";
  const collectionDatetime = moment.tz(
    `${collectionTime.date} ${collectionTime.time}`,
    datetimeFormat,
    dealershipTimezone,
  ).format();

  const params = {
    appointment_id: appointmentId,
    channel_key,
    collection_time: collectionDatetime,
    job_type: jobType,
    technician_id: technicianId,
    notes,
    dealership_id: dealershipId,
    location_area,
    distance_limit_ignored,
    geolocation,
    ...addressData,
    distance_in_miles: distance,
    overcapacity,
  };

  return createNewMobileTechnicianJob(dealershipId, technicianId, params, authToken);
};

const updateBookingLoadingState = () => {
  return {
    type: BOOKING_DETAILS_UPDATE_APPOINTMENT,
  };
};

export const updateLocalBooking = ({ payload }) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));
  const { id: appointment_id } = bookingDetailsDataSelector(getState());
  const params = {
    repair_order_tag: payload.repair_order_tag,
    internal_note: payload.internal_note,
    promised_datetime: payload.promised_datetime,
  };

  return updateLocalAppointment(dealership_id, appointment_id, params, token)
    .then((response) => response)
    .catch((error) => dispatch({
      type: BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE,
      payload: error.message,
    }));
};

export const updateBookingToArrived = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));
  const { id: appointment_id } = bookingDetailsDataSelector(getState());

  updateAppointmentToArrived(dealership_id, appointment_id, token);

  dispatch({ type: BOOKING_DETAILS_SET_ARRIVED });
};

export const updateAppointment = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));
  const channel_key = authChannelKeySelector(getState());

  let customerId = bookingDetailsDataSelector(getState()).customer?.id;
  const phoneNumber = bookingDetailsDataSelector(getState()).customer?.phone_number;

  const {
    menu_items,
    vehicle: { id: vehicle_id },
    id: appointment_id,
    appraisal_requested,
    recall_ids,
    overcapacity,
    walkin,
    appointment_datetime,
  } = bookingDetailsDataSelector(getState());

  const {
    clientWaiting: client_waiting,
    selectedServices,
    chosenTimeSlot,
    selectedTransport: transport_kind,
    selectedAdvisor,
    sendSms,
  } = bookingDetailsSelector(getState());

  let quarterSlot;
  let full_date;

  if (Object.keys(chosenTimeSlot).length > 0) {
    quarterSlot = chosenTimeSlot.quarterSlot;
    full_date = chosenTimeSlot.day.full_date;
  } else {
    const { owner_customer_id } = bookingVehicleData(getState());
    customerId = owner_customer_id;
    quarterSlot = dateFns.format(appointment_datetime, "HH:mm");
    full_date = dateFns.format(appointment_datetime, "YYYY-MM-DD");
  }

  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";

  const isPickUp = bookingDetailsIsPickUpSelector(getState());
  const isDropOff = bookingDetailsIsDropOffSelector(getState());
  const isMobileTechnician = bookingDetailsisMobileTechnicianSelector(getState());
  const activePickupJob = bookingActivePickupJobSelector(getState());
  const activeDropOffJob = bookingActiveDropOffJobSelector(getState());
  const activeMobileTechnicianJob = bookingActiveMobileTechnicianJobSelector(getState());
  const serviceLocation = bookingServiceLocationSelector(getState());

  if (isPickUp || isMobileTechnician) {
    const remoteJobData = bookingDetailsRemoteJobDataSelector(getState());

    const collectionTime = moment.tz(
      `${full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();

    const params = {
      appointment_id,
      channel_key,
    };

    let job;
    let jobId;
    let dropOffJob;
    let dropOffJobId;

    if (isPickUp) {
      const {
        pickUp: {
          jobId: id,
          notes,
          mainDriverId,
          coDriverId,
          location_area,
          overcapacity: pickUpOvercapacity,
        },
      } = remoteJobData;

      jobId = id;

      job = {
        ...params,
        collection_time: collectionTime,
        main_driver_id: mainDriverId,
        co_driver_id: coDriverId,
        notes,
        distance_limit_ignored: serviceLocation?.pickUp?.distanceLimitIgnored,
        ...serviceLocation?.pickUp?.addressData,
        geolocation: serviceLocation?.pickUp?.geolocation,
        job_type: "pickup",
        location_area,
        distance_in_miles: serviceLocation?.pickUp?.distance,
        overcapacity: pickUpOvercapacity,
      };

      if (isDropOff) {
        const {
          dropOff: {
            jobId: dropOffId,
            notes: dropOffNotes,
            mainDriverId: dropOffMainDriverId,
            coDriverId: dropOffCoDriverId,
            collectionTime: dropOffCollectionTime,
            location_area: dropOffLocationArea,
            overcapacity: dropOffOvercapacity,
          },
        } = remoteJobData;

        const dropOffDateTime = moment.tz(
          dropOffCollectionTime?.date
            ? `${dropOffCollectionTime.date} ${dropOffCollectionTime.time}`
            : dropOffCollectionTime,
          datetimeFormat,
          dealershipTimezone,
        ).format();

        dropOffJobId = dropOffId;

        dropOffJob = {
          ...params,
          collection_time: dropOffDateTime,
          main_driver_id: dropOffMainDriverId,
          co_driver_id: dropOffCoDriverId,
          notes: dropOffNotes,
          distance_limit_ignored: serviceLocation?.dropOff?.distanceLimitIgnored,
          ...serviceLocation?.dropOff?.addressData,
          geolocation: serviceLocation?.dropOff?.geolocation,
          job_type: "dropoff",
          location_area: dropOffLocationArea,
          distance_in_miles: serviceLocation?.pickUp?.distance,
          overcapacity: dropOffOvercapacity,
        };
      }
    } else {
      const {
        mobileTechnician: {
          jobId: id,
          notes,
          technicianId,
          location_area,
          overcapacity: mobileTechnicianOvercapacity,
        },
      } = remoteJobData;

      jobId = id;

      job = {
        ...params,
        collection_time: collectionTime,
        technician_id: technicianId,
        location_area,
        notes,
        distance_limit_ignored: serviceLocation?.pickUp?.distanceLimitIgnored,
        ...serviceLocation?.pickUp?.addressData,
        geolocation: serviceLocation?.pickUp?.geolocation,
        job_type: "mobile_technician",
        distance_in_miles: serviceLocation?.pickUp?.distance,
        overcapacity: mobileTechnicianOvercapacity,
      };
    }

    if (jobId) {
      if (isPickUp && activePickupJob) {
        dispatch(updateDriverJobState());
        updateDriverJob(
          dealership_id,
          job.main_driver_id,
          jobId,
          job,
          token,
        );
      }

      if (isMobileTechnician && activeMobileTechnicianJob) {
        dispatch(updateMobileTechnicianJobState());
        updateMobileTechnicianJob(
          dealership_id,
          job.technician_id,
          jobId,
          job,
          token,
        );
      }
    }

    if (
      (jobId && isPickUp && !activePickupJob)
      || (jobId && isMobileTechnician && !activeMobileTechnicianJob)
      || !jobId
    ) {
      if (isPickUp) {
        dispatch(setNewDriverJobState());
        createNewDriverJob(
          dealership_id,
          job.main_driver_id,
          job,
          token,
        );
      }

      if (isMobileTechnician) {
        dispatch(setNewMobileTechnicianJobState());
        createNewMobileTechnicianJob(
          dealership_id,
          job.technician_id,
          job,
          token,
        );
      }
    }

    if (isDropOff && dropOffJobId) {
      dispatch(updateDriverJobState());
      updateDriverJob(
        dealership_id,
        dropOffJob.main_driver_id,
        dropOffJobId,
        dropOffJob,
        token,
      );
    }

    if (isDropOff && !dropOffJobId) {
      dispatch(setNewDriverJobState());
      createNewDriverJob(
        dealership_id,
        dropOffJob.main_driver_id,
        dropOffJob,
        token,
      );
    }
  }

  if (!isPickUp && activePickupJob) {
    const {
      id, main_driver,
    } = activePickupJob;

    cancelDriverJob(
      dealership_id,
      main_driver.id,
      id,
      token,
    );
  }

  if ((!isPickUp || !isDropOff) && activeDropOffJob) {
    const {
      id, main_driver,
    } = activeDropOffJob;

    cancelDriverJob(
      dealership_id,
      main_driver.id,
      id,
      token,
    );
  }

  if (!isMobileTechnician && activeMobileTechnicianJob) {
    const {
      id, technician,
    } = activeMobileTechnicianJob;

    cancelMobileTechnicianJob(
      dealership_id,
      technician.id,
      id,
      token,
    );
  }

  const appointmentDatetime = moment.tz(
    `${full_date} ${quarterSlot}`,
    datetimeFormat,
    dealershipTimezone,
  ).format();

  const menu_item_ids = selectedServices
    .filter(({ kind }) => kind !== "other" && kind !== "recall")
    .map((service) => service.id);

  const menu_item_comments = selectedServices
    .filter((service) => service.additionalInfo)
    .map(({ id, additionalInfo }) => {
      return {
        id,
        comment: formatInput(additionalInfo),
      };
    });
  const decision_tree_results = selectedServices
    .filter((service) => service.decisionTreeResult)
    .map(({ id, decisionTreeResult, decision_tree }) => {
      const details = [{
        source: SOURCE_CONCIERGE,
        text: decision_tree.intro.text,
      }];

      Object.entries(decisionTreeResult).forEach(([key, value]) => {
        details.push({
          source: SOURCE_CONCIERGE,
          text: key,
        });
        details.push({
          source: SOURCE_USER,
          text: value,
        });
      });

      return {
        menu_item_id: id,
        details,
      };
    });
  const booking_menu_item_ids = menu_items
    .filter((bookingItem) => selectedServices.find(
      (selected) => selected.name === bookingItem.name
          || selected.id === bookingItem.menu_item_id,
    ))
    .map((service) => service.id);

  let serviceType = "inhouse";

  if (isPickUp) {
    serviceType = "pickup";
  } else if (isMobileTechnician) {
    serviceType = "mobile_technician";
  }

  const params = {
    client_waiting,
    transport_kind: isMobileTechnician ? null : transport_kind,
    phone_number: phoneNumber || null,
    vehicle_id,
    customer_id: customerId || null,
    appointment_id,
    service_advisor_id: selectedAdvisor.service_advisor_id || selectedAdvisor.id,
    appointment_datetime: appointmentDatetime,
    booking_menu_item_ids,
    decision_tree_results,
    menu_item_comments,
    menu_item_ids,
    channel_key,
    dealership_id,
    appraisal_requested,
    send_sms: sendSms,
    overcapacity,
    walkin,
    recall_ids,
    service_type: serviceType,
    pick_up_address_data: isPickUp ? serviceLocation?.pickUp?.addressData : undefined,
    drop_off_address_data: isDropOff ? serviceLocation?.dropOff?.addressData : undefined,
    mobile_technician_address_data: isMobileTechnician
      ? serviceLocation?.pickUp?.addressData
      : undefined,
  };

  dispatch(updateBookingLoadingState());

  return updateBooking(dealership_id, appointment_id, params, token)
    .then((response) => response)
    .catch((error) => dispatch({
      type: BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE,
      payload: error.message,
    }));
};

export const triggerCheckinSms = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));
  const channel_key = authChannelKeySelector(getState());

  dispatch({ type: BOOKING_DETAILS_TRIGGER_CHECKIN_SMS });
  const {
    customer: { phone_number },
    id: appointment_id,
  } = bookingDetailsDataSelector(getState());

  const params = {
    phone_number,
    channel_key,
    dealership_id,
    appointment_id,
  };

  sendCheckinSms(dealership_id, appointment_id, params, token)
    .then((response) => console.log(response))
    .catch((error) => dispatch({
      type: BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE,
      payload: error,
    }));
};

const setFetchVideosState = () => {
  return {
    type: BOOKING_DETAILS_FETCH_VIDEOS,
    payload: {
      isLoading: true,
    },
  };
};

export const retrieveVideos = ({ vin, dates, appointmentId }) => (dispatch, getState) => {
  dispatch(setFetchVideosState());

  const token = authTokenSelector(getState());
  const dealershipId = Number(dealershipIdSelector(getState()));
  const params = {
    dealershipId,
    vin,
    dates,
  };

  fetchAppointmentVideos(dealershipId, appointmentId, token).then((response) => {
    const videos = response.reduce(
      (acc, video) => {
        if (video.video_type === "pickup") {
          acc.pickup.push(video);
        } else if (video.video_type === "dropoff") {
          acc.dropoff.push(video);
        } else if (video.video_type === "mobile_technician") {
          acc.mobileTechnician.push(video);
        } else if (video.video_type === "arrived") {
          acc.arrived.push(video);
        } else {
          acc.walkaround.push(video);
        }

        return acc;
      },
      {
        pickup: [],
        dropoff: [],
        mobileTechnician: [],
        arrived: [],
        walkaround: [],
      },
    );
    dispatch({
      type: BOOKING_DETAILS_FETCH_VIDEOS_SUCCESS,
      payload: videos,
    });

    return videos;
  }).then((data) => {
    fetchVideos(params)
      .then((response) => {
        const videos = response.videos.reduce(
          (acc, video) => {
            if (video.jobType === "pickup") {
              acc.pickup.push(video);
            } else if (video.jobType === "dropoff") {
              acc.dropoff.push(video);
            } else if (video.jobType === "mobile_technician") {
              acc.mobileTechnician.push(video);
            } else if (video.jobType === "arrived") {
              acc.arrived.push(video);
            } else {
              acc.walkaround.push(video);
            }

            return acc;
          },
          data,
        );

        return dispatch({
          type: BOOKING_DETAILS_FETCH_VIDEOS_SUCCESS,
          payload: videos,
        });
      }).catch((error) => dispatch({
        type: BOOKING_DETAILS_FETCH_VIDEOS_FAILURE,
        payload: { error },
      }));
  }).catch((error) => dispatch({
    type: BOOKING_DETAILS_FETCH_VIDEOS_FAILURE,
    payload: { error },
  }));
};

export const updateVehicleSetData = ({
  id,
  make,
  model,
  model_year,
  vin,
  mileage,
  external_model_code,
  plate_number,
}) => (dispatch, getState) => {
  dispatch({ type: BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA });

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const params = {
    id,
    make,
    model,
    model_year,
    model_code: external_model_code,
    vin,
    mileage,
    plate_number: plate_number || null,
    channel_key,
    dealership_id,
  };

  updateVehicleSet(dealership_id, params, token)
    .then((response) => console.log(response))
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE,
        payload: error,
      });
    });
};

export const updateCustomerData = (data, customer) => (dispatch, getState) => {
  dispatch({ type: BOOKING_DETAILS_UPDATE_CUSTOMER_DATA });

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const dummyData = JSON.parse(JSON.stringify(data));

  const customerData = {};

  Object.keys(dummyData).forEach((key) => {
    customerData[key] = dummyData[key] || null;
  });

  const params = {
    customer: customerData,
    customer_id: customer.id,
    channel_key,
    dealership_id,
  };

  updateCustomer(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE,
        payload: error,
      });
    });
};

export const createCustomer = (data, booking_id) => (dispatch, getState) => {
  dispatch({ type: BOOKING_DETAILS_CREATE_CUSTOMER });

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const dummyData = JSON.parse(JSON.stringify(data));

  if (!dummyData.address_line1) {
    dummyData.address_line1 = dummyData.address;
  }

  const params = {
    ...dummyData,
    channel_key,
    dealership_id,
    appointment_id: booking_id,
    source: SCHEDULER_SOURCE,
  };

  createNewCustomer(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => {
      dispatch({
        type: BOOKING_DETAILS_CREATE_CUSTOMER_FAILURE,
        payload: error,
      });
    });
};

export const clearTimeSlots = () => (dispatch) => {
  dispatch({
    type: BOOKING_DETAILS_FETCH_DAYS_SUCCESS,
    payload: [],
  });
  dispatch({
    type: BOOKING_DETAILS_FETCH_JOB_SLOTS_SUCCESS,
    payload: [],
  });
  dispatch({
    type: BOOKING_DETAILS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS,
    payload: [],
  });
};

export const setIsPickUp = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_IS_PICK_UP,
    payload,
  };
};

export const setIsMobileTechnician = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_IS_MOBILE_TECHNICIAN,
    payload,
  };
};

export const setIsDistanceLimitIgnored = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_IS_DISTANCE_LIMIT_IGNORED,
    payload,
  };
};

export const setRemoteJobData = (payload) => {
  return {
    type: BOOKING_DETAILS_SET_BOOKING_REMOTE_JOB_DATA,
    payload,
  };
};

export const initChecksJobDistance = (addressData, geolocation, jobType) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: BOOKING_DETAILS_JOB_REACHABLE_LOADING,
    payload: jobType,
  });

  const authToken = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const channelKey = authChannelKeySelector(getState());

  const params = {
    channel_key: channelKey,
    ...addressData,
    lat: geolocation.lat,
    lng: geolocation.lng,
    job_type: jobType,
  };

  return checksJobDistance(dealershipId, params, authToken);
};

export const resetJobReachable = (reachable, jobType) => (
  dispatch,
) => {
  dispatch({
    type: BOOKING_DETAILS_JOB_REACHABLE,
    payload: {
      reachable,
      jobType,
    },
  });
};

export const retrieveDealershipDrivers = () => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const response = await fetchDealershipDrivers(
      dealershipId,
      {
        active: true,
      },
      token,
    );

    dispatch({
      type: BOOKING_DETAILS_FETCH_DRIVERS_INFO_SUCCESS,
      payload: {
        drivers: response.data,
        driversTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: BOOKING_DETAILS_FETCH_DRIVERS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const setRemoteServiceLocation = (serviceLocation) => async (dispatch) => {
  dispatch({
    type: BOOKING_DETAILS_SET_REMOTE_SERVICE_LOCATION,
    payload: serviceLocation,
  });
};

export const retrieveDealershipMobileTechnicians = () => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const response = await fetchDealershipMobileTechnicians(
      dealershipId,
      {
        active: true,
      },
      token,
    );

    dispatch({
      type: BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS,
      payload: {
        technicians: response.data,
        techniciansTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const updateJob = (job, isPickUp = true) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const channelKey = authChannelKeySelector(getState());

  const {
    id,
    appointment_id,
    collection_time,
    main_driver,
    co_driver,
    technician,
    notes,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
    location_area,
    job_type,
    geolocation,
    overcapacity,
  } = job;

  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";
  const collectionTime = moment.tz(
    collection_time,
    datetimeFormat,
    dealershipTimezone,
  ).format();

  let params = {
    appointment_id,
    channel_key: channelKey,
    collection_time: collectionTime,
    notes,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
    job_type,
    geolocation,
    location_area,
    overcapacity: overcapacity || false,
  };

  if (isPickUp) {
    params = {
      ...params,
      main_driver_id: main_driver.id,
      co_driver_id: co_driver?.id || null,
    };
  } else {
    params = {
      ...params,
      technician_id: technician.id,
    };
  }

  dispatch(updateDriverJobState());

  if (isPickUp) {
    updateDriverJob(
      dealershipId,
      main_driver.id,
      id,
      params,
      token,
    );
  } else {
    updateMobileTechnicianJob(
      dealershipId,
      technician.id,
      id,
      params,
      token,
    );
  }
};
