export const ADMIN_ROLE = "admin";
export const MANAGER_ROLE = "dealership_admin";
export const ADVISOR_ROLE = "advisor";
export const BDC_ROLE = "bdc";

export const ARRIVE_SOURCE = "arrive";
export const CUSTOMER_SOURCE = "customer";
export const CUSTOMER_APP_SOURCE = "customer_app";
export const IMPORT_SOURCE = "import";
export const SCHEDULER_SOURCE = "scheduler";
export const PROACTIVE_SOURCE = "customer_proactive";

export const BUSINESS_CUSTOMER_TYPE = "business";
export const INDIVIDUAL_CUSTOMER_TYPE = "individual";

export const FIRST_AVAILABLE_ADVISOR_ID = "firstAvailable";

export const TRANSPORT_INTEGRATIONS = ["uber", "lyft", "loaner", "shuttle"];

export const integrationSettingsKeys = [
  {
    integrationType: "audi",
    settings: {
      dealer_code: "",
    },
  },
  {
    integrationType: "cdk",
    settings: {
      api_url: null,
      dealer_id: null,
      dispatch_code: null,
      labor_type: null,
      service_writer_id: null,
      imported_makes: [],
      shared_account: false,
    },
  },
  {
    integrationType: "dealer_track",
    settings: {
      enterprise_code: null,
      company_number: null,
      server_name: null,
      service_writer_id: null,
      api_url: null,
      password: null,
      printer_id: null,
      username: null,
      imported_makes: [],
      shared_account: false,
    },
  },
  {
    integrationType: "dealerware",
    settings: {},
  },
  {
    integrationType: "fortellis",
    settings: {
      parts_printer: null,
      subscription_id: null,
      workorder_copies: null,
      workorder_printer: null,
    },
  },
  {
    integrationType: "google_analytics",
    settings: {
      api_secret: "",
      ga4_measurement_id: "",
    },
  },
  {
    integrationType: "loaner",
    settings: {
      loaners_per_day: 0,
      min_money_spent: 0,
      min_appointment_duration: 0,
      both_conditions_required: false,
    },
  },
  {
    integrationType: "lyft",
    settings: {
      api_key: null,
      additional_information_for_customers: "",
    },
  },
  {
    integrationType: "mobile_technician",
    settings: {
      max_pickups_per_hour: 0,
      slots_interval: 1,
      time_variance: 1,
      max_distance: 1,
      segments_number: null,
    },
  },
  {
    integrationType: "pickup",
    settings: {
      max_distance: 1,
      max_pickups_per_hour: 0,
      slots_interval: 1,
      time_variance: 1,
      min_money_spent: 0,
      both_conditions_required: false,
      min_appointment_duration: 0,
      segments_number: 0,
      additional_information_for_customers: "",
    },
  },
  {
    integrationType: "reynolds_&_reynolds",
    settings: {},
  },
  {
    integrationType: "shuttle",
    settings: {
      api_key: null,
      additional_information_for_customers: "",
    },
  },
  {
    integrationType: "stripe",
    settings: {
      api_key: null,
    },
  },
  {
    integrationType: "twilio",
    settings: {
      sid: null,
      token: null,
      phone_number: null,
      country_code: null,
    },
  },
  {
    integrationType: "vw_recalls",
    settings: {
      api_key: null,
    },
  },
  {
    integrationType: "uber",
    settings: {
      client_id: null,
      client_secret: null,
      server_token: null,
      bearer_token: null,
      additional_information_for_customers: "",
    },
  },
];

export const segmentNumberOptions = [
  {
    label: "4",
    value: "4",
    selectable: true,
  },
  {
    label: "6",
    value: "6",
    selectable: true,
  },
  {
    label: "8",
    value: "8",
    selectable: true,
  },
];

export const DESC = "desc";
export const ASC = "asc";
export const DEFAULT_SORT_COLUMN_BOOKINGS = "appointment_datetime";
export const DEFAULT_SORT_COLUMN_DEALERSHIP = "created_at";
export const DEFAULT_SORT_DIRECTION_MENU_TEMPLATES = ASC;
export const DEFAULT_SORT_DIRECTION_BOOKINGS = ASC;
export const DEFAULT_SORT_DIRECTION = DESC;

export const BOOKING_STEP_SERVICES = "BOOKING_STEP_SERVICES";
export const BOOKING_STEP_RECALLS = "BOOKING_STEP_RECALLS";
export const BOOKING_STEP_REMOTE_SERVICE = "BOOKING_STEP_REMOTE_SERVICE";
export const BOOKING_STEP_ADVISOR = "BOOKING_STEP_ADVISOR";
export const BOOKING_STEP_TIMESLOT = "BOOKING_STEP_TIMESLOT";
export const BOOKING_STEP_DROP_OFF_TIMESLOT = "BOOKING_STEP_DROP_OFF_TIMESLOT";
export const BOOKING_STEP_COMMUNICATION = "BOOKING_STEP_COMMUNICATION";
export const BOOKING_STEP_TRANSPORT = "BOOKING_STEP_TRANSPORT";
export const BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER = "BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER";
export const BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER = "BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER";
export const BOOKING_STEP_MOBILE_TECHNICIAN = "BOOKING_STEP_MOBILE_TECHNICIAN";
export const BOOKING_STEP_INTERNAL_NOTE = "BOOKING_STEP_INTERNAL_NOTE";

export const SAME_NUMBER_OR_PLATE_ERROR = "Something went wrong. Make sure, that there is no car with the same plate number or VIN.";

export const MOBILE_TECHNICIAN_INTEGRATION_KIND = "mobile_technician";
export const PICK_UP_INTEGRATION_KIND = "pickup";

export const detaultYearsList = [...Array(20).keys()].map(
  (i) => new Date().getFullYear() - i,
);

export const DEFAULT_WORKING_AREAS = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
};

export const NOTIFICATION_DELAY = 1000;
