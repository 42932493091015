import React, { useState } from "react";
import {
  arrayOf, func, number, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import { createTeamTag } from "store/actions/settings-actions";
import { settingsIntegrationsSelector } from "store/selectors/settings-selectors";
import cx from "classnames";
import Input from "components/common/Input";
import Modal from "components/common/Modal";
import SwitchWithLabel from "components/common/SwitchWithLabel";
import { formatInput } from "shared/utils/common";

const AddTeamTagModal = ({
  onClose,
  addTeamTag,
  integrations,
}) => {
  const [fields, setFields] = useState({
    name: "",
    expectedUpsell: 0,
    members: [],
    mobile: false,
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    const { name, expectedUpsell } = fields;
    const result = {
      name: !name && "Name must be present.",
      expectedUpsell: (
        (expectedUpsell === undefined || expectedUpsell < 0)
        && "Expected Upcell must be present and positive."
      ),
    };
    setErrors(result);
    return !Object.values(result).find((v) => v);
  };

  const submit = () => {
    const { name, expectedUpsell, mobile } = fields;
    if (validate()) {
      addTeamTag({
        name: formatInput(name),
        mobile,
        expected_upsell: +expectedUpsell,
      })
        .then(() => onClose())
        .catch(({ response: { data } }) => setErrors({
          name: data.name,
          expectedUpsell: data.expected_upsell,
          members: data.members,
        }));
    }
  };

  const setField = (field, value) => setFields(
    (prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    },
  );

  const mobileTechnicianIntegrationPresent = integrations.filter((integration) => integration.kind === "mobile_technician").length > 0;

  return (
    <Modal
      title="New team"
      cancelButtonText="Cancel"
      submitButtonText="Create"
      size="small"
      onCancel={onClose}
      onSubmit={submit}
    >
      <Input
        inputClassName={cx({ error: !!errors.name })}
        label="Name"
        error={errors.name}
        value={fields.name}
        onChange={(value) => setField("name", value)}
      />
      <Input
        inputClassName={cx({ error: !!errors.expectedUpsell })}
        type="number"
        step="0.1"
        max="20"
        min="0"
        label="Expected Upsell"
        error={errors.expectedUpsell}
        value={fields.expectedUpsell}
        onChange={(value) => value >= 0 && setField("expectedUpsell", value)}
      />
      <SwitchWithLabel
        id="mobile-team-switch"
        label="Mobile technician"
        checked={fields.mobile}
        onChange={(value) => setField("mobile", value)}
        isVisible={mobileTechnicianIntegrationPresent}
      />
    </Modal>
  );
};

AddTeamTagModal.propTypes = {
  onClose: func.isRequired,
  addTeamTag: func.isRequired,
  integrations: arrayOf(shape({
    id: number.isRequired,
    kind: string.isRequired,
  })),
};

AddTeamTagModal.defaultProps = {
  integrations: [],
};

const mapStateToProps = (state) => {
  return {
    integrations: settingsIntegrationsSelector(state),
  };
};

export default connect(mapStateToProps, {
  addTeamTag: createTeamTag,
})(AddTeamTagModal);
