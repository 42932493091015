import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, number, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import {
  settingsDealershipMobileTechnicianEnabledSelector,
  settingsTeamTagsLoadingStateSelector,
  settingsTeamTagsSelector,
} from "store/selectors/settings-selectors";
import {
  retrieveTeamTags,
  updateTeamTagsSeniority,
} from "store/actions/settings-actions";
import TableRowDraggable from "components/common/ReactTableElements/TableRowDraggable";
import Panel from "components/common/Panel";
import Button from "components/common/Button";
import TeamTagsSettingsActionCell from "components/common/ReactTableElements/cellRenderers/TeamTagsSettingsActionCell";
import MembersCell from "./MembersCell";
import AddTeamTagModal from "./AddTeamTagModal";

import "./styles.scss";

const TeamTagsPanel = ({
  teamTags,
  isTeamTagsLoading,
  onUpdateSeniority,
  fetchTeamTags,
  isMobileTechnician,
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    fetchTeamTags();
  }, []);

  const setDropDownPosition = () => {
    const panelBody = document.querySelector(".conciergeSettingsPageBody");
    const panelBodyRect = panelBody.getBoundingClientRect();
    const inputWrappers = document.querySelectorAll(".multiTagListInner");

    inputWrappers.forEach((inputWrapper) => {
      const inputWrapperRect = inputWrapper.getBoundingClientRect();
      const element = inputWrapper.querySelector(".multiTagSelectMenu");

      if (element) {
        if (
          (inputWrapperRect.bottom + 180) < window.innerHeight
          && panelBody.scrollHeight === panelBodyRect.height
        ) {
          element.style.position = "fixed";
          element.style.removeProperty("top");
          element.style.removeProperty("bottom");
        } else if ((inputWrapperRect.bottom + 180) < panelBodyRect.bottom) {
          element.style.position = "absolute";
          element.style.top = "35px";
          element.style.removeProperty("bottom");
        } else {
          element.style.position = "absolute";
          element.style.bottom = "35px";
          element.style.removeProperty("top");
        }
      }
    });
  };

  const membersCell = (props) => (
    <MembersCell
      tagId={props.row.original.id}
      value={props.value}
      changeVisibilityAction={setDropDownPosition}
    />
  );

  const teamTagsSettingsActionCell = (props) => (
    <TeamTagsSettingsActionCell value={props.value} />
  );

  const mobileCell = (props) => {
    if (props.value) {
      return "Yes";
    }

    return "No";
  };

  const columns = [
    {
      Header: "SENIORITY",
      accessor: "seniority",
      style: {
        width: "50px",
        flex: "50 0 auto",
      },
    },
    {
      Header: "NAME",
      accessor: "name",
      style: {
        width: "50px",
        flex: "50 0 auto",
        whiteSpace: "normal",
      },
    },
    {
      Header: "MOBILE",
      accessor: "mobile",
      style: {
        width: "50px",
        flex: "50 0 auto",
        whiteSpace: "normal",
      },
      Cell: mobileCell,
    },
    {
      Header: "EXPECTED UPSELL 1/10h",
      accessor: "expected_upsell",
      style: {
        width: "100px",
        flex: "100 0 auto",
      },
    },
    {
      Header: "MEMBERS",
      accessor: "members",
      style: {
        width: "200px",
        flex: "200 0 auto",
      },
      miNwidth: 200,
      Cell: membersCell,
    },
    {
      Header: "",
      accessor: "id",
      style: {
        width: "50px",
        flex: "50 0 auto",
      },
      Cell: teamTagsSettingsActionCell,
    },
  ];

  const header = (
    <div className="conciergeSettingsPagePanelHeader">
      <p>Team</p>
      <Button
        variant="dark"
        style={{ width: "210px" }}
        onClick={() => setIsCreateModalOpen(true)}
      >
        + Add new team
      </Button>
    </div>
  );

  return (
    <Panel
      header={header}
      className="conciergeSettingsPageTeamTagsPanel"
    >
      <TableRowDraggable
        columns={isMobileTechnician
          ? columns
          : columns.filter((item) => item.accessor !== "mobile")}
        data={teamTags}
        loading={isTeamTagsLoading}
        onRowDrop={(records) => {
          onUpdateSeniority({
            team_tags_seniorities: records.map(({ id }, index) => {
              return {
                id,
                seniority: index + 1,
              };
            }),
          });
        }}
      />
      {isCreateModalOpen && (
        <AddTeamTagModal onClose={() => setIsCreateModalOpen(false)} />
      )}
    </Panel>
  );
};

TeamTagsPanel.propTypes = {
  isTeamTagsLoading: bool.isRequired,
  teamTags: arrayOf(shape({
    id: number,
    name: string,
    expected_upsell: number,
    members: arrayOf(shape({
      id: number,
      name: string,
    })),
  })).isRequired,
  onUpdateSeniority: func.isRequired,
  fetchTeamTags: func.isRequired,
  value: string,
  row: shape({
    original: shape({
      id: string,
    }),
  }),
  isMobileTechnician: bool.isRequired,
};

TeamTagsPanel.defaultProps = {
  value: null,
  row: shape({
    original: shape({
      id: null,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    isTeamTagsLoading: settingsTeamTagsLoadingStateSelector(state),
    teamTags: settingsTeamTagsSelector(state),
    isMobileTechnician: settingsDealershipMobileTechnicianEnabledSelector(state),
  };
};

const actions = {
  onUpdateSeniority: updateTeamTagsSeniority,
  fetchTeamTags: retrieveTeamTags,
};

export default connect(mapStateToProps, actions)(TeamTagsPanel);
