import React from "react";
import Switch from "react-switch";
import { bool, func, string } from "prop-types";
import styles from "./styles.module.scss";
import { activeBoxShadow, offColor, onColor } from "./config/common";

const SwitchWithLabel = ({
  id,
  label,
  checked,
  isVisible,
  disabled,
  onChange,
  containerClass,
  labelClass,
  switchClass,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={`${styles.switchContainer} ${containerClass}`}>
      {label && (
        <label
          className={`${styles.switchLabel} ${labelClass}`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <Switch
        id={id}
        className={`${styles.switch} ${switchClass}`}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        onColor={onColor}
        offColor={offColor}
        activeBoxShadow={activeBoxShadow}
        aria-labelledby={`${id}-label`}
      />
    </div>
  );
};

SwitchWithLabel.propTypes = {
  label: string,
  id: string.isRequired,
  checked: bool.isRequired,
  isVisible: bool,
  disabled: bool,
  onChange: func.isRequired,
  containerClass: string,
  labelClass: string,
  switchClass: string,
};

SwitchWithLabel.defaultProps = {
  isVisible: true,
  disabled: false,
  label: "",
  containerClass: "",
  labelClass: "",
  switchClass: "",
};

export default SwitchWithLabel;
