import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, number, oneOfType, string,
} from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

import { opCodePropType } from "shared/prop-types";
import { opCodesSelector } from "store/selectors/menu-template-selectors";
import { getOpCodes } from "store/actions/menu-template-actions";
import SwitchWithLabel from "../SwitchWithLabel";

import "./styles.scss";

const OpCodesSelect = ({
  showAssigned,
  opCode,
  opCodes,
  onChange,
  fetchOpCodes,
  isDisabled,
}) => {
  const [options, setSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [opCodeItem, setOpCodeItem] = useState(null);
  const [showAssignedOpCodes, setShowAssignedOpCodes] = useState(showAssigned);

  useEffect(() => {
    setIsLoading(false);
    if (opCodes) {
      setSelectOptions(opCodes.map(((oc) => {
        return {
          label: `${oc.operation_code} - ${oc.description}`,
          value: oc.id,
          code: oc.operation_code,
        };
      })));
    }
  }, [opCodes]);

  useEffect(() => {
    setOpCodeItem(options.filter((item) => item.code === opCode)[0]);
  }, [options, opCode]);

  useEffect(() => {
    let delayedSearch = null;

    const params = {
      show_assigned: showAssignedOpCodes,
    };

    if (searchPhrase || opCode) {
      params.search_phrase = searchPhrase || opCode;
    }

    const opCodesSearch = () => {
      setIsLoading(true);
      fetchOpCodes(params);
    };
    const delay = searchPhrase === "" ? 100 : 500;
    delayedSearch = setTimeout(() => {
      opCodesSearch();
    }, delay);

    return () => {
      clearTimeout(delayedSearch);
    };
  }, [searchPhrase, opCode]);

  return (
    <>
      <Select
        isClearable
        classNamePrefix="addFallbackOpCodeSelect"
        placeholder="Select or search"
        loadingMessage={() => "Searching for op codes..."}
        noOptionsMessage={() => "No op codes found."}
        isLoading={isLoading}
        inputValue={searchPhrase}
        onInputChange={setSearchPhrase}
        value={opCodeItem}
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
      />
      <SwitchWithLabel
        id="opcodes-switch"
        label="Show assigned Op Codes"
        checked={showAssignedOpCodes}
        onChange={() => setShowAssignedOpCodes((prev) => !prev)}
        disabled={isDisabled}
      />
    </>

  );
};

OpCodesSelect.propTypes = {
  showAssigned: bool,
  opCode: oneOfType([number, string]),
  onChange: func.isRequired,
  opCodes: arrayOf(opCodePropType),
  fetchOpCodes: func.isRequired,
  isDisabled: bool,
};

OpCodesSelect.defaultProps = {
  showAssigned: false,
  opCode: null,
  opCodes: [],
  isDisabled: false,
};

const mapStateToProps = (state) => {
  return {
    opCodes: opCodesSelector(state),
  };
};

const actions = {
  fetchOpCodes: getOpCodes,
};

export default connect(mapStateToProps, actions)(OpCodesSelect);
