import React from "react";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import { isEmpty } from "ramda";

import {
  chosenTimeSlotPropType,
  customerPropType,
  serviceAdvisorPropType,
  servicePropType,
  vehiclePropType,
} from "shared/prop-types";

import SelectedServicesAndTotalCost from "./SelectedServicesAndTotalCost";
import BookAppointmentButton from "./BookAppointmentButton";
import OwnerInformation from "./OwnerInformation";
import AdvisorInformation from "./AdvisorInformation";
import PickupInformation from "./PickupInformation";

import styles from "./styles.module.scss";

const AppointmentDetails = ({
  vehicle,
  customer,
  selectedServices,
  selectedRecalls,
  selectedAdvisor,
  chosenTimeSlot,
  sendSms,
  contactNumber,
  isPickUp,
  isDropOff,
  isMobileTechnician,
  remoteJobData,
  onSave,
  saveButtonLabel,
  jobReachable,
  capacityBufferTime,
  driverBufferTime,
  ignoreDistance,
}) => {
  const isRemoteComplete = () => {
    if (isPickUp) {
      return remoteJobData
       && !isEmpty(remoteJobData?.pickUp?.addressData)
       && remoteJobData?.pickUp?.mainDriverId
       && (
         !isDropOff
        || (
          isDropOff
          && !isEmpty(remoteJobData?.dropOff?.addressData)
          && remoteJobData?.dropOff?.mainDriverId
        )
       );
    }

    if (isMobileTechnician) {
      return remoteJobData
       && !isEmpty(remoteJobData?.mobileTechnician?.addressData)
       && remoteJobData?.mobileTechnician?.technicianId;
    }

    return true;
  };

  const allServices = () => selectedServices.concat(selectedRecalls);

  const servicesInvalid = () => {
    if (!selectedServices.length && !selectedRecalls.length) {
      return true;
    }

    let invalid = false;

    selectedServices.forEach((service) => {
      if (
        !invalid
        && service.kind === "concern"
        && !service.decision_tree
        && (!service.additionalInfo || service.additionalInfo.length === 0)
      ) {
        invalid = true;
      }
    });

    return invalid;
  };

  const isAppointmentComplete = () => (
    allServices().length
    && !servicesInvalid()
    && chosenTimeSlot.quarterSlot
    && isRemoteComplete()
    && selectedAdvisor
    && (
      selectedAdvisor.id !== "firstAvailable" || selectedAdvisor.service_advisor_id
    )
    && (jobReachable === null || jobReachable || ignoreDistance)
  );

  return (
    <div className={styles.container}>
      <OwnerInformation
        customer={customer}
        vehicle={vehicle}
        sendSms={sendSms}
        contactNumber={contactNumber}
      />
      {isPickUp
      && remoteJobData?.pickUp?.address
      && remoteJobData?.pickUp?.collectionTime
      && (
        <PickupInformation
          address={remoteJobData.pickUp.address}
          collectionTime={remoteJobData.pickUp.collectionTime}
        />
      )}
      {selectedAdvisor && (<AdvisorInformation advisor={selectedAdvisor} />)}
      {!!(isEmpty(selectedAdvisor) && selectedServices.length) && (
        <div className={styles.section}>Please select the advisor</div>
      )}
      {allServices().length ? (
        <SelectedServicesAndTotalCost
          selectedServices={allServices()}
          bufferTime={isMobileTechnician ? driverBufferTime : capacityBufferTime}
        />
      ) : (
        <div className={styles.section}>
          Please add services to book an appointment
        </div>
      )}
      {!!(allServices().length && selectedAdvisor && isEmpty(chosenTimeSlot)) && (
        <div className={styles.section}>
          {isPickUp
            ? "Please select collection time to book pick-up"
            : "Please select time of arrival to book an appointment"}
        </div>
      )}
      <div className={styles.divider} />
      <BookAppointmentButton
        isAppointmentComplete={!!isAppointmentComplete()}
        onClick={onSave}
        label={saveButtonLabel}
      />
    </div>
  );
};

AppointmentDetails.propTypes = {
  vehicle: vehiclePropType,
  customer: customerPropType,
  selectedServices: arrayOf(servicePropType),
  selectedRecalls: arrayOf(servicePropType),
  selectedAdvisor: arrayOf(serviceAdvisorPropType),
  chosenTimeSlot: chosenTimeSlotPropType,
  sendSms: bool.isRequired,
  isPickUp: bool.isRequired,
  isDropOff: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  contactNumber: number.isRequired,
  remoteJobData: objectOf(node),
  onSave: func,
  saveButtonLabel: string,
  jobReachable: bool,
  driverBufferTime: string.isRequired,
  capacityBufferTime: string.isRequired,
  ignoreDistance: bool,
};

AppointmentDetails.defaultProps = {
  vehicle: {},
  customer: {},
  selectedServices: [],
  selectedRecalls: [],
  selectedAdvisor: null,
  chosenTimeSlot: {},
  remoteJobData: {},
  onSave: () => {},
  saveButtonLabel: "",
  jobReachable: null,
  ignoreDistance: false,
};

export default AppointmentDetails;
