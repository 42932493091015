import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import { formatName } from "shared/utils/common";
import {
  integrationPropType,
} from "shared/prop-types";
import { updatePickupIntegrationOptions } from "store/actions/settings-actions";
import { segmentNumberOptions } from "shared/constants";
import HeaderEdit from "components/common/HeaderEdit";
import Input from "components/common/Input";
import Accordion from "components/common/Accordion";
import StyledSelect from "components/common/StyledSelect";
import SwitchWithLabel from "components/common/SwitchWithLabel";

import styles from "../styles.module.scss";

const PickUpIntegration = ({
  integration: {
    active,
    settings,
    id,
  },
  updateIntegrationHandler,
}) => {
  const [pickUpSettings, setPickUpSettings] = useState({});
  const [isActive, setIsActive] = useState(active);
  const [isEditing, setIsEditing] = useState(false);
  const [erros, setErrors] = useState({});

  useEffect(() => {
    setPickUpSettings(settings);
  }, [settings]);

  const validate = (item) => {
    const result = {
      max_distance: item.max_distance < 1 || item.max_distance === "",
      time_variance: item.time_variance < 1 || item.time_variance === "",
      slots_interval: item.slots_interval < 1 || item.slots_interval === "",
    };
    setErrors(result);

    return !Object.values(result).find((v) => v);
  };

  const save = () => {
    const params = {
      settings: pickUpSettings,
      active: isActive,
    };
    if (validate(pickUpSettings)) {
      updateIntegrationHandler(id, params);
      setIsEditing(false);
    }
  };

  const cancelChanges = () => {
    setPickUpSettings({ ...settings });
    setIsActive(active);
    setErrors({});
    setIsEditing(false);
  };

  const setSegmentsNumber = (value) => {
    setPickUpSettings({
      ...pickUpSettings,
      segments_number: Number(value),
    });
  };

  return (
    <Accordion
      title="Pick-up and Drop-off"
      customClass={styles.accordion}
    >
      <section className={styles.container}>
        <SwitchWithLabel
          id="active-switch"
          label="Active"
          disabled={!isEditing}
          checked={isActive}
          onChange={() => setIsActive((prev) => !prev)}
        />
        <section className={styles.inputsContainer}>
          {Object.keys(settings).map((keyName) => (
            keyName !== "both_conditions_required"
            && keyName !== "segments_number"
          ) && (
            <Input
              label={keyName === "min_appointment_duration"
                ? "Minimum appointment duration (minutes)"
                : formatName(
                  keyName === "slots_interval"
                    ? "slots_interval_(minutes)"
                    : keyName,
                )}
              value={pickUpSettings[keyName]}
              onChange={(value) => setPickUpSettings((prev) => {
                return {
                  ...prev,
                  [keyName]: value,
                };
              })}
              key={keyName}
              type={keyName === "note_for_customers" ? "text" : "number"}
              min={
                keyName === "max_distance" || keyName === "time_variance"
                  ? 1
                  : 0
              }
              disabled={!isEditing}
              error={erros[keyName] && `${formatName(keyName)} cannot be less than 1`}
            />
          ))}
          <div className={styles.dropdown}>
            <StyledSelect
              key="integration_type"
              label={formatName("segments_number")}
              options={segmentNumberOptions}
              disabled={!isEditing}
              value={{
                label: pickUpSettings.segments_number,
                value: pickUpSettings.segments_number,
                selectable: true,
              }}
              onChange={(val) => setSegmentsNumber(val.value)}
            />
          </div>
          <SwitchWithLabel
            id="pick-up-requirements-switch"
            label="Both conditions required"
            disabled={!isEditing}
            checked={pickUpSettings.both_conditions_required}
            onChange={() => setPickUpSettings((prev) => {
              return {
                ...prev,
                both_conditions_required: !prev.both_conditions_required,
              };
            })}
            containerClass={styles.switch}
          />
        </section>
      </section>
      <HeaderEdit
        isEditing={isEditing}
        handleCancel={cancelChanges}
        handleSave={save}
        handleEdit={() => setIsEditing(true)}
      />
    </Accordion>
  );
};

PickUpIntegration.propTypes = {
  integration: integrationPropType.isRequired,
  updateIntegrationHandler: func.isRequired,
};

const actions = {
  updateIntegrationHandler: updatePickupIntegrationOptions,
};

export default connect(null, actions)(PickUpIntegration);
