import React from "react";
import uniqid from "uniqid";
import { formatName } from "shared/utils/common";
import { func, shape, string } from "prop-types";
import SwitchWithLabel from "components/common/SwitchWithLabel";
import StyledSelect from "components/common/StyledSelect";
import { segmentNumberOptions } from "shared/constants";
import Input from "components/common/Input";

import styles from "../styles.module.scss";

const optionsSwitch = ["both_conditions_required", "shared_account"];

const IntegrationSettingsFields = ({
  keyName,
  integrationType,
  errors,
  settings,
  onChange,
}) => {
  const { [integrationType]: { [keyName]: keyNameError } } = errors || {};

  if (optionsSwitch.includes(keyName)) {
    return (
      <SwitchWithLabel
        id={`${keyName}-switch`}
        label={formatName(keyName)}
        onChange={() => onChange(!settings[keyName], keyName)}
        checked={settings[keyName]}
        containerClass={styles.switch}
      />
    );
  }

  if (keyName === "segments_number") {
    return (
      <div
        key={uniqid()}
        className={styles.selectWrapper}
      >
        <label
          className={styles.label}
          htmlFor="segments-number-switch"
        >
          {formatName(keyName)}
        </label>
        <StyledSelect
          key="integration_type"
          options={segmentNumberOptions}
          value={{
            label: settings.segments_number,
            value: settings.segments_number,
            selectable: true,
          }}
          onChange={(val) => onChange(Number(val.value), "segments_number")}
          error={errors[integrationType] && errors[integrationType].segments_number}
        />
      </div>
    );
  }

  return (
    <div
      className={styles.inputContainer}
      key={keyName}
    >
      <Input
        label={formatName(keyName)}
        key={keyName}
        className={styles.input}
        value={settings[keyName] || ""}
        onChange={(value) => onChange(value, keyName)}
        error={keyNameError}
      />
    </div>
  );
};

IntegrationSettingsFields.propTypes = {
  onChange: func.isRequired,
  keyName: string.isRequired,
  integrationType: string.isRequired,
  settings: shape.isRequired,
  errors: shape.isRequired,
};

IntegrationSettingsFields.defaultProps = {};

export default IntegrationSettingsFields;
