import React, { Component } from "react";
import ReactTimePicker from "react-time-picker";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import { dealershipInfoPropType } from "shared/prop-types";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

import { changeDealershipInfo } from "store/actions/settings-actions";
import {
  dealershipIdSelector,
  delershipOpCodeMapSelector,
} from "store/selectors/app-selectors";
import { WEEK_DAYS, weekDaysHumanFormat } from "shared/utils/datetime";
import {
  convertArrayToSelectOptions,
  formatInput,
  getAsyncImage,
} from "shared/utils/common";

import { US_STATES } from "shared/constants/us_states";
import { NOTIFICATION_DELAY } from "shared/constants";

import Panel from "components/common/Panel";
import Block from "components/common/Block";
import Input from "components/common/Input";
import StyledSelect from "components/common/StyledSelect";
import ImageInput from "components/common/ImageInput";
import HeaderEdit from "components/common/HeaderEdit";
import SwitchWithLabel from "components/common/SwitchWithLabel";

import styles from "./styles.module.scss";

class GeneralPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      logo: null,
      welcome_screen: null,
      name: "",
      address: "",
      address_line: "",
      address_zipcode: "",
      address_city: "",
      address_state: "",
      phone: "",
      email: "",
      manager_name: "",
      manager_phone: "",
      manager_email: "",
      working_hours: {},
      logoForSending: null,
      welcomeScreenForSending: null,
      shouldDeleteWelcomeScreen: false,
      shouldDeleteLogo: false,
      localization: false,
      init: false,
    };
  }

  componentDidMount() {
    this.resetState();
  }

  componentDidUpdate() {
    if (!this.state.init && Object.keys(this.props.dealershipInfo).length > 0) {
      this.resetState();
      this.setState({ init: true });
    }
  }

  resetState = () => {
    const {
      logo,
      welcome_screen,
      name,
      address,
      address_line,
      address_city,
      address_state,
      address_zipcode,
      phone,
      email,
      manager_name,
      manager_phone,
      manager_email,
      working_hours,
      localization_for_customers_enabled,
    } = this.props.dealershipInfo;

    this.setState({
      logo,
      welcome_screen,
      name: name || "",
      address: address || "",
      address_line: address_line || "",
      address_city: address_city || "",
      address_state: address_state || "",
      address_zipcode: address_zipcode || "",
      phone: phone || "",
      email: email || "",
      manager_name: manager_name || "",
      manager_phone: manager_phone || "",
      manager_email: manager_email || "",
      working_hours: working_hours || {},
      isEditing: false,
      errors: {},
      localization: localization_for_customers_enabled,
    });
  };

  handleInputChange = (fieldName, value) => {
    if (this.state.isEditing) {
      this.setState({ [fieldName]: value });
    }
  };

  validateAndSetZipcode = (field, value) => {
    if (value.match((/^[0-9]+$/)) != null) {
      this.handleInputChange(field, value);
    } else if (value.length === 1 || value.length === 0) {
      this.handleInputChange(field, "");
    }
  };

  handleWorkingHoursChange = (field, key, prefix, value) => {
    if (this.state.isEditing) {
      if (value === null) {
        const workingHours = field;
        delete workingHours[key][prefix];
        this.setState({
          working_hours: {
            ...field,
            [key]: {
              ...workingHours[key],
            },
          },
        });
      } else {
        this.setState({
          working_hours: {
            ...field,
            [key]: {
              ...field[key],
              [prefix]: value,
            },
          },
        });
      }
    }
  };

  handleLogoChange = (e) => {
    const logo = e.target.files[0];
    const logoUrl = URL.createObjectURL(logo);
    this.setState({
      logoForSending: logo,
      logo: { url: logoUrl },
      shouldDeleteLogo: false,
    });
    NotificationManager.success(
      "New logo was successfully uploaded. Don't forget to save it.",
      "Success",
      NOTIFICATION_DELAY,
    );
  };

  handleImageChange = (e) => {
    const image = e.target.files[0];
    const imageUrl = URL.createObjectURL(image);

    getAsyncImage(imageUrl)
      .then((img) => {
        if (img.naturalWidth < 1080 || img.naturalHeight < 1920) {
          NotificationManager.warning(
            "The minimum size requirements for the  image is 1080x1920 pixels.",
            "Warning",
            NOTIFICATION_DELAY,
          );
          return;
        }
        this.setState({
          welcomeScreenForSending: image,
          welcome_screen: { url: imageUrl },
          shouldDeleteWelcomeScreen: false,
        });
        NotificationManager.success(
          "New image was successfully uploaded. Don't forget to save it.",
          "Success",
          NOTIFICATION_DELAY,
        );
      });
  };

  handleDeletingLogo = () => this.setState({
    logo: null,
    logoForSending: null,
    shouldDeleteLogo: true,
  });

  handleDeletingWelcomeScreen = () => this.setState({
    welcome_screen: null,
    welcomeScreenForSending: null,
    shouldDeleteWelcomeScreen: true,
  });

  enableEditing = () => this.setState({ isEditing: true });

  validateFields = () => {
    const {
      address_line,
      address_state,
      address_zipcode,
      address_city,
      name,
    } = this.state;

    const errors = {
      name: !name && "Name must be present",
      address_line: !address_line && "Address line must be present",
      address_state: !address_state && "State must be present",
      address_city: !address_city && "City must be present",
      address_zipcode: (!address_zipcode || address_zipcode.length !== 5) && "Zip code must be exactly 5 characters long",
    };

    const isNoError = (
      !errors.address_line
      && !errors.address_state
      && !errors.address_city
      && !errors.address_zipcode
      && !errors.name
    );

    this.setState({
      errors,
    });

    return isNoError;
  };

  validateWorkingHours = () => {
    const { working_hours } = this.state;
    const errorDays = Object.keys(working_hours).map((keyName) => {
      const workingHoursFrom = parseInt(working_hours[keyName].from, 10);
      const workingHoursTo = parseInt(working_hours[keyName].to, 10);
      const isWeekend = Number.isNaN(workingHoursFrom) && Number.isNaN(workingHoursTo);
      const isCorrect = workingHoursFrom < workingHoursTo;
      const isValid = isWeekend || isCorrect;
      return {
        weekDay: WEEK_DAYS[keyName],
        isValid,
      };
    }).filter((day) => !day.isValid).reduce((acc, day) => [
      ...acc, day.weekDay,
    ], []);
    if (errorDays.length) {
      NotificationManager.error(
        errorDays.length === 1
          ? `Check Working Hours for ${errorDays}.`
          : `Check Working Hours for the next days: ${errorDays.join(", ")}.`,
        "Error",
        NOTIFICATION_DELAY,
      );
    }
    return !errorDays.length;
  };

  saveChanges = () => {
    const {
      updateDealershipInfo,
      dealershipId,
    } = this.props;
    const {
      name,
      address,
      address_line,
      address_city,
      address_state,
      address_zipcode,
      phone,
      email,
      manager_name,
      manager_phone,
      manager_email,
      working_hours,
      logoForSending,
      welcomeScreenForSending,
      shouldDeleteWelcomeScreen,
      shouldDeleteLogo,
      localization,
    } = this.state;

    const isValid = this.validateFields()
      && this.validateWorkingHours();

    if (isValid) {
      const dataToSend = new FormData();
      dataToSend.append("name", formatInput(name));
      dataToSend.append("address", formatInput(address));
      dataToSend.append("address_line", formatInput(address_line));
      dataToSend.append("address_city", formatInput(address_city));
      dataToSend.append("address_state", formatInput(address_state));
      dataToSend.append("address_zipcode", address_zipcode);
      dataToSend.append("phone", formatInput(phone));
      dataToSend.append("email", formatInput(email));
      dataToSend.append("manager_name", formatInput(manager_name));
      dataToSend.append("manager_phone", formatInput(manager_phone));
      dataToSend.append("manager_email", formatInput(manager_email));
      dataToSend.append("working_hours", JSON.stringify(working_hours));
      dataToSend.append("time_zone", this.props.dealershipInfo.time_zone);
      dataToSend.append("localization_for_customers_enabled", localization);

      if (logoForSending) {
        dataToSend.append("logo", logoForSending);
      } else if (shouldDeleteLogo) {
        dataToSend.append("remove_logo", true);
      }
      if (welcomeScreenForSending) {
        dataToSend.append("welcome_screen", welcomeScreenForSending);
      } else if (shouldDeleteWelcomeScreen) {
        dataToSend.append("remove_welcome_screen", true);
      }
      updateDealershipInfo(dealershipId, dataToSend);
      this.setState({ isEditing: false });
    }
  };

  render() {
    const {
      isEditing,
      errors,
      logo,
      welcome_screen,
      name,
      address,
      address_line,
      address_city,
      address_state,
      address_zipcode,
      phone,
      email,
      manager_name,
      manager_phone,
      manager_email,
      working_hours,
      localization,
    } = this.state;

    const header = (
      <HeaderEdit
        isEditing={isEditing}
        handleCancel={this.resetState}
        handleEdit={this.enableEditing}
        handleSave={this.saveChanges}
      />
    );

    return (
      <>
        <NotificationContainer />
        <Panel
          header={header}
          className={styles.panel}
        >
          <Block
            title="Dealership details"
            className={styles.generalBlock}
          >
            <ImageInput
              onImageChange={this.handleLogoChange}
              onDelete={this.handleDeletingLogo}
              isEditing={isEditing}
              inputName="logoInput"
              image={logo}
              alt="concierge dealership logo"
              imagePresentText="Upload Logo"
              noImageText="No Logo"
            />
            <ImageInput
              onImageChange={this.handleImageChange}
              onDelete={this.handleDeletingWelcomeScreen}
              isEditing={isEditing}
              inputName="imageInput"
              image={welcome_screen}
              alt="concierge dealership welcome screen"
              imagePresentText="Upload Welcome Screen Image"
              noImageText="No Welcome Screen Image"
            />
            <div className={styles.name}>
              <Input
                label="Name"
                type="text"
                value={name}
                disabled={!isEditing}
                onChange={(value) => this.handleInputChange("name", value)}
                error={errors?.name}
              />
            </div>
            {!isEditing && (
              <Input
                label="Address"
                type="text"
                value={address}
                disabled={!isEditing}
                onChange={(value) => this.handleInputChange("address", value)}
              />
            )}
            {isEditing && (
              <>
                <Input
                  label="Address line"
                  type="text"
                  value={address_line}
                  disabled={!isEditing}
                  onChange={(value) => this.handleInputChange("address_line", value)}
                  error={errors.address_line}
                />
                <Input
                  label="Zipcode"
                  maxLength={5}
                  type="text"
                  value={address_zipcode}
                  disabled={!isEditing}
                  onChange={(value) => this.validateAndSetZipcode("address_zipcode", value)}
                  error={errors.address_zipcode}
                />
                <Input
                  label="City"
                  type="text"
                  value={address_city}
                  disabled={!isEditing}
                  onChange={(value) => this.handleInputChange("address_city", value)}
                  error={errors.address_city}
                />
                <div className={styles.input}>
                  <StyledSelect
                    label="State"
                    value={{
                      label: `${address_state}`,
                      value: `${address_state}`,
                    }}
                    options={convertArrayToSelectOptions(US_STATES)}
                    className="conciergeSettingsPageSelect"
                    onChange={(obj) => this.handleInputChange("address_state", obj.value)}
                    isOptionDisabled={(option) => option.selectable === false}
                    error={errors.address_state}
                  />
                </div>
              </>
            )}
            <Input
              label="Phone number"
              type="tel"
              value={phone}
              disabled={!isEditing}
              onChange={(value) => this.handleInputChange("phone", value)}
            />
            <Input
              label="Email"
              type="email"
              value={email}
              disabled={!isEditing}
              onChange={(value) => this.handleInputChange("email", value)}
            />
            <SwitchWithLabel
              id="localization-switch"
              label="Widget localization"
              checked={localization}
              onChange={() => this.setState({ localization: !localization })}
              isVisible={isEditing}
            />
          </Block>
          <div className={styles.centralContainer}>
            <Block
              title="Service Manager"
              className={styles.generalBlock}
            >
              <Input
                label="Name"
                type="text"
                value={manager_name}
                disabled={!isEditing}
                onChange={(value) => this.handleInputChange("manager_name", value)}
              />
              <Input
                label="Phone"
                type="tel"
                value={manager_phone}
                disabled={!isEditing}
                onChange={(value) => this.handleInputChange("manager_phone", value)}
              />
              <Input
                label="Email"
                type="email"
                value={manager_email}
                disabled={!isEditing}
                onChange={(value) => this.handleInputChange("manager_email", value)}
              />
            </Block>
          </div>
          <Block
            title="Working Hours"
            className={styles.timeBlock}
          >
            {Object.keys(working_hours).map((keyName) => (
              <div className={styles.timeField}>
                <div className={styles.timeFieldLabel}>
                  {weekDaysHumanFormat(keyName)}
                </div>
                <div className={styles.timeFieldBlock}>
                  <ReactTimePicker
                    label="from"
                    value={working_hours[keyName].from}
                    disabled={!isEditing}
                    onChange={(value) => this.handleWorkingHoursChange(working_hours, keyName, "from", value)}
                    key={`${keyName}_from`}
                    className={styles.timeFieldInput}
                    disableClock
                    format="hh:mma"
                  />
                  <ReactTimePicker
                    label="to"
                    value={working_hours[keyName].to}
                    disabled={!isEditing}
                    onChange={(value) => this.handleWorkingHoursChange(working_hours, keyName, "to", value)}
                    key={`${keyName}_to`}
                    className={styles.timeFieldInput}
                    disableClock
                    format="hh:mma"
                  />
                </div>
              </div>
            ))}
          </Block>
        </Panel>
      </>
    );
  }
}

GeneralPanel.propTypes = {
  dealershipInfo: dealershipInfoPropType,
  dealershipId: string,
  updateDealershipInfo: func.isRequired,
};

GeneralPanel.defaultProps = {
  dealershipInfo: null,
  dealershipId: null,
};

const mapStateToProps = (state) => {
  return {
    dealershipId: dealershipIdSelector(state),
    opCodeMap: delershipOpCodeMapSelector(state),
  };
};

const actions = {
  updateDealershipInfo: changeDealershipInfo,
};

export default connect(mapStateToProps, actions)(GeneralPanel);
