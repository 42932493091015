import React, { useEffect, useState } from "react";
import {
  arrayOf, func, number, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import { updateTeamTag } from "store/actions/settings-actions";
import { settingsIntegrationsSelector, settingsTeamTagsSelector } from "store/selectors/settings-selectors";
import Input from "components/common/Input";
import Modal from "components/common/Modal";
import SwitchWithLabel from "components/common/SwitchWithLabel";

const EditTeamTagModal = ({
  team_tags,
  integrations,
  id,
  changeTeamTag,
  onClose,
}) => {
  const [data, setData] = useState({
    name: "",
    expected_upsell: 0,
    mobile: false,
  });

  useEffect(() => {
    const currentTeamTag = team_tags.find((tag) => tag.id === id);
    if (currentTeamTag) {
      setData(currentTeamTag);
    }
  }, []);

  const submit = () => {
    changeTeamTag(data.id, {
      id: data.id,
      name: data.name,
      mobile: data.mobile,
      expected_upsell: data.expected_upsell,
    });
    onClose();
  };

  const handleInputChange = (fieldName, value) => setData(
    (items) => {
      return {
        ...items,
        [fieldName]: value,
      };
    },
  );

  const mobileTechnicianIntegrationPresent = integrations.filter((integration) => integration.kind === "mobile_technician").length > 0;

  return (
    <Modal
      title="Edit team"
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="small"
      onCancel={onClose}
      onSubmit={submit}
    >
      <Input
        label="Name"
        value={data.name}
        onChange={(value) => handleInputChange("name", value)}
      />
      <Input
        label="Expected upsell 1/10h"
        type="number"
        step="0.1"
        min="0"
        max="20"
        value={data.expected_upsell}
        onChange={(value) => value >= 0 && value <= 20 && handleInputChange("expected_upsell", value)}
      />
      <SwitchWithLabel
        id="mobile-team-switch"
        label="Mobile technician"
        checked={data.mobile}
        onChange={(value) => handleInputChange("mobile", value)}
        isVisible={mobileTechnicianIntegrationPresent}
      />
    </Modal>
  );
};

EditTeamTagModal.propTypes = {
  changeTeamTag: func,
  onClose: func.isRequired,
  id: number.isRequired,
  integrations: arrayOf(shape({
    id: number.isRequired,
    kind: string.isRequired,
  })),
  team_tags: arrayOf(shape({
    id: number.isRequired,
    dealership_id: number.isRequired,
    name: string,
    members: arrayOf(shape({
      id: number,
      name: string,
    })),
  })),
};

EditTeamTagModal.defaultProps = {
  changeTeamTag: () => {},
  team_tags: [],
  integrations: [],
};

const mapStateToProps = (state) => {
  return {
    integrations: settingsIntegrationsSelector(state),
    team_tags: settingsTeamTagsSelector(state),
  };
};

const actions = {
  changeTeamTag: updateTeamTag,
};

export default connect(mapStateToProps, actions)(EditTeamTagModal);
