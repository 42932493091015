import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import {
  arrayOf, bool, func, number, oneOf, shape, string,
} from "prop-types";
import { driverPropType, mobileTechniciansPropType, serviceAdvisorPropType } from "shared/prop-types";
import Pagination from "components/common/ReactTableElements/Pagination";
import Panel from "components/common/Panel";
import WorkingHoursCell from "components/common/ReactTableElements/cellRenderers/WorkingHoursCell";
import EmployeeSettingsActionCellAdvisors from "components/common/ReactTableElements/cellRenderers/EmployeeSettingsActionCell/EmployeeSettingsActionCellAdvisors";
import EmployeeSettingsActionCellDriver from "components/common/ReactTableElements/cellRenderers/EmployeeSettingsActionCell/EmployeeSettingsActionCellDriver";
import EmployeeSettingsActionCellMobileTechnicians from "components/common/ReactTableElements/cellRenderers/EmployeeSettingsActionCell/EmployeeSettingsActionCellMobileTechnicians";
import searchIcon from "assets/images/search.svg";
import Button from "components/common/Button";
import { ASC, DESC } from "shared/constants";
import SwitchWithLabel from "components/common/SwitchWithLabel";
import AddEmployeeModal from "./AddEmployeeModal";

import "./styles.scss";

const EmployeesPanel = ({
  type,
  fetchEmployees,
  employees,
  total,
  needToResyncDriversData,
  addNewEmployee,
  isLoading,
}) => {
  const [page, setCurrentPage] = useState(1);
  const [pageSize, onPageSizeChange] = useState(5);
  const [sort, setSort] = useState("active desc");
  const [openedPopupId, setPopupId] = useState(null);
  const [search, setSearch] = useState("");
  const [activeOnly, setActiveOnly] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const clearPopupId = () => setPopupId(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    fetchEmployees(page, pageSize, activeOnly || null, search, sort);
  }, [page, pageSize, activeOnly, search, sort]);

  useEffect(() => {
    if (needToResyncDriversData) {
      fetchEmployees(page, pageSize, activeOnly || null, search, sort);
    }
  }, [needToResyncDriversData]);

  useEffect(() => {
    if (employees.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }
  }, [employees]);

  const updatePopupId = (popupId) => {
    if (openedPopupId !== popupId) {
      setPopupId(popupId);
    } else {
      clearPopupId();
    }
  };

  const handleSwitch = () => {
    setCurrentPage(1);
    setActiveOnly(!activeOnly);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSortedChange = (newSorted, column) => {
    const sortColumn = column.sort_column ? column.sort_column : column.id;
    const direction = newSorted[0].desc ? DESC : ASC;
    setSort(`${sortColumn} ${direction}`);
  };

  const photoCell = (props) => {
    if (props.value) {
      return (
        <div key={props.original.id}>
          <img
            className="photo"
            src={(process.env.NODE_ENV === "development"
              ? `${process.env.REACT_APP_ROOT_URL}${props.value}`
              : props.value)}
            alt=""
          />
        </div>
      );
    }

    return null;
  };

  const capacityCell = (props) => {
    const updatedProps = {
      ...props,
      employeeId: props.original.id,
      isActive: props.original.active,
    };
    return (
      <WorkingHoursCell
        key={props.original.id}
        {...updatedProps}
      />
    );
  };

  const idCell = (props) => {
    let workingHours = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    if (Object.keys(props.original.working_hours).length !== 0) {
      workingHours = props.original.working_hours;
    }

    let updatedProps = {
      ...props,
      type,
      id: props.value,
      name: props.original.name,
      isActive: props.original.active,
      photo: props.original.photo,
      workingHours,
      openPopup: updatePopupId,
      closePopup: clearPopupId,
      openedPopupId,
    };

    if (type === "advisor") {
      updatedProps = {
        ...updatedProps,
        availability: props.original.availability,
        employeeNumber: props.original.employee_number,
        employmentId: props.original.employment_id,
        employeeTeamTags: props.original.team_tags,
        printerNumber: props.original.printer_number,
        printerCopies: props.original.printer_copies,
      };

      return <EmployeeSettingsActionCellAdvisors {...updatedProps} />;
    }

    if (type === "driver") {
      updatedProps = {
        ...updatedProps,
        employmentId: props.value,
        phoneNumber: props.original.phone_number,
      };

      return <EmployeeSettingsActionCellDriver {...updatedProps} />;
    }

    if (type === "mobile") {
      updatedProps = {
        ...updatedProps,
        employmentId: props.value,
        phoneNumber: props.original.phone_number,
      };

      return <EmployeeSettingsActionCellMobileTechnicians {...updatedProps} />;
    }
  };

  const columns = [
    {
      Header: "Photo",
      accessor: "photo.url",
      Cell: photoCell,
      sortable: false,
    },
    {
      Header: "Name",
      accessor: type === "advisor" ? "dms_name" : "name",
      sort_column: "name",
    },
    {
      Header: type === "advisor" ? "Employee" : "Phone Number",
      accessor: type === "advisor" ? "employee_number" : "phone_number",
      sort_column: type === "advisor" ? "employee_number" : "phone_number",
    },
    {
      Header: "Capacity",
      accessor: "working_hours",
      minWidth: 140,
      Cell: capacityCell,
      sort_column: "active",
    },
    {
      Header: "",
      accessor: "id",
      Cell: idCell,
      sortable: false,
    },
  ];

  const label = () => {
    if (type === "advisor") {
      return "Advisors";
    }

    if (type === "driver") {
      return "Drivers";
    }

    if (type === "mobile") {
      return "Mobile Technicians";
    }
  };

  const header = (
    <div className="conciergeSettingsPagePanelHeader">
      <p>{label()}</p>
      <SwitchWithLabel
        id="active-switch"
        label="Active only"
        checked={activeOnly}
        onChange={handleSwitch}
        containerClass="employeesSwitch"
      />
      <form className="employeesSearch">
        <input
          className="employeesSearchInput"
          placeholder="Search for advisor"
          value={search}
          onChange={handleSearch}
        />
        <img
          alt="search"
          className="employeesSearchIcon"
          src={searchIcon}
        />
      </form>
      {type === "driver" && (
        <Button
          style={{
            width: "210px",
            marginLeft: "16px",
          }}
          variant="dark"
          onClick={() => setCreateModalOpen(true)}
        >
          + Add new driver
        </Button>
      )}
    </div>
  );

  return (
    <div>
      <Panel
        header={header}
        className="conciergeSettingsPageCapacityTabPanel"
      >
        <div className="conciergeSettingsPageCapacityTabPanelBody">
          <ReactTable
            data={employees}
            total={total}
            columns={columns}
            defaultPageSize={pageSize}
            PaginationComponent={Pagination}
            onPageChange={setCurrentPage}
            onPageSizeChange={onPageSizeChange}
            onSortedChange={onSortedChange}
            page={page}
            minRows={1}
            noDataText="No advisors found"
            manual
            loading={isLoading}
          />
        </div>
        {createModalOpen && (
          <AddEmployeeModal
            type={type}
            label={label().slice(0, -1)}
            onSubmit={addNewEmployee}
            onClose={() => setCreateModalOpen(false)}
          />
        )}
      </Panel>
    </div>
  );
};

EmployeesPanel.propTypes = {
  type: oneOf([
    "advisor",
    "driver",
    "mobile",
  ]).isRequired,
  fetchEmployees: func.isRequired,
  employees: arrayOf(
    oneOf([
      serviceAdvisorPropType,
      driverPropType,
      mobileTechniciansPropType,
    ]),
  ),
  total: number.isRequired,
  original: shape({
    id: string,
    active: bool,
  }),
  value: string,
  needToResyncDriversData: bool,
  addNewEmployee: func,
  isLoading: bool.isRequired,
};

EmployeesPanel.defaultProps = {
  employees: [],
  original: shape({
    id: null,
    active: false,
  }),
  value: null,
  needToResyncDriversData: false,
  addNewEmployee: () => {},
};

export default EmployeesPanel;
